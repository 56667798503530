var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from "@mui/material";
function SkeletonNavBar() {
    return (_jsx("div", { className: "max-w-sm", children: _jsx("div", { className: "flex flex-col space-y-2 mt-1", children: __spreadArray([], Array(4), true).map(function (_, ind) { return (_jsxs("div", { className: "pt-4", children: [_jsx(Skeleton, { variant: "rectangular", width: Math.random() * 100 + 80, height: 20 }), _jsx("div", { className: "space-y-2 mt-4", children: __spreadArray([], Array(Math.floor(Math.random() * 5 + 5)), true).map(function (__, ind2) { return (_jsx("div", { className: "space-y-2", children: _jsx(Skeleton, { variant: "rectangular", width: Math.random() * 90 + 180, height: 20 }) }, ind2)); }) })] }, ind)); }) }) }));
}
export default SkeletonNavBar;
