import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import DSRFlow from "pages/Client/Case/DSR/ClientView/Components/DSRFlow";
import Tags from "pages/Client/Case/DSR/ClientView/Components/Tags";
import OpenInquiries from "pages/Client/Case/DSR/ClientView/Components/OpenInquiries";
import DSInfo from "pages/Client/Case/DSR/ClientView/Components/DSInfo";
import DSRHistory from "pages/Client/Case/DSR/ClientView/Components/DSRHistory";
import DSRHeader from "pages/Client/Case/DSR/ClientView/Components/DSRHeader";
import DSGeoLocation from "pages/Client/Case/DSR/ClientView/Components/DSGeoLocation";
import InquiryModal from "pages/Client/Case/DSR/ClientView/Components/InquiryModal";
import tolgee from "services/translation";
export default function DSRView() {
    useEffect(function () {
        document.title = tolgee.t({ key: "dsr_request", ns: "dsr" });
    }, []);
    return (_jsxs("div", { className: "flex flex-col mx-auto max-w-7xl @container", children: [_jsx(DSRHeader, {}), _jsx(Tags, {}), _jsx(DSRFlow, {}), _jsx(OpenInquiries, {}), _jsx(DSInfo, {}), _jsx(DSGeoLocation, {}), _jsx(DSRHistory, {}), _jsx(InquiryModal, {})] }));
}
