var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { Button, FormControl, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import tolgee from "services/translation";
import { api } from "services";
import { notify } from "store/app";
import { BaseModal } from "components";
import { QuillEditor } from "components/Input";
import { useCase } from "pages/Client/Case/CaseContext";
var defaultValues = {
    subject: "",
    text: "",
};
export default function InternalCommentModal(props) {
    var _this = this;
    var state = useCase()[0];
    var dispatch = useDispatch();
    var modalState = props.modalState, handleReload = props.handleReload;
    var open = modalState[0], setOpen = modalState[1];
    var _a = useForm({
        defaultValues: defaultValues,
    }), handleSubmit = _a.handleSubmit, register = _a.register, reset = _a.reset, watch = _a.watch, setValue = _a.setValue;
    var textData = watch("text");
    var onEditorStateChange = function (editorState) {
        setValue("text", editorState, { shouldDirty: true });
    };
    var onSubmit = function (data) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.internalComment(state.case.uuid, data)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        dispatch(notify({ message: tolgee.t({ key: "comment_added", ns: "dsr" }), type: "SUCCESS" }));
                        reset();
                        modalState[1](false);
                    }
                    else {
                        dispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                    }
                    handleReload();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(BaseModal, { modalState: [open, setOpen], className: "overflow-auto", closeEvent: function () {
            reset();
        }, children: _jsxs("div", { className: "space-y-4 w-110", children: [_jsx("h3", { className: "text-center", children: _jsx(T, { ns: "dsr", keyName: "internal_comment" }) }), _jsxs(FormControl, { fullWidth: true, className: "space-y-4", children: [_jsx(TextField, __assign({ label: tolgee.t({ key: "subject_optional", ns: "dsr" }) }, register("subject"))), _jsx(QuillEditor, { onChange: onEditorStateChange, value: textData })] }), _jsx("div", { className: "flex justify-end pt-2", children: _jsx(Button, { onClick: handleSubmit(onSubmit), variant: "contained", disabled: (textData === null || textData === void 0 ? void 0 : textData.length) === 0, children: _jsx(T, { ns: "generic", keyName: "save" }) }) })] }) }));
}
