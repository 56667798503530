var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { exemptionList } from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/helpers";
import ConfirmInquiry from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/ConfirmInquiry";
import tolgee from "services/translation";
import { api } from "services";
import { notify } from "store/app";
import { BaseIconButton, BaseModal } from "components";
import { PRIGHTER_BLUE } from "const/color";
import { useCase } from "pages/Client/Case/CaseContext";
import { finalizeCheck } from "store/thunks";
import { isCheckFinished } from "helpers/case";
export default function Exemptions() {
    var _this = this;
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var isFinished = isCheckFinished(state);
    var appDispatch = useDispatch();
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(null), modalText = _c[0], setModalText = _c[1];
    var _d = useFormContext(), register = _d.register, watch = _d.watch, getValues = _d.getValues, setValue = _d.setValue;
    var exemptions = watch("exemptions");
    var getFilteredExemptions = function (unfilteredExemptions) {
        return Object.values(unfilteredExemptions).flatMap(function (obj) {
            return Object.values(obj).flatMap(function (innerArr) { return innerArr.filter(function (item) { return item.checked === true; }); });
        });
    };
    var handleSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
        var values, filteredValues, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    values = getValues().exemptions;
                    filteredValues = getFilteredExemptions(values);
                    return [4 /*yield*/, api.putExemptions(state.case.uuid, filteredValues)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        appDispatch(notify({
                            message: tolgee.t({
                                key: "exemptions_added",
                                ns: "dsr",
                            }),
                            type: "SUCCESS",
                        }));
                        finalizeCheck()(dispatch, state.case.uuid, state.ui.check);
                    }
                    else {
                        appDispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: "flex flex-col space-y-4 mt-4", children: [_jsx(T, { keyName: "description.exemptions", ns: "ccpa" }), _jsx(ConfirmInquiry, { handleSendInquiry: function () { return dispatch({ type: "SEND_INQUIRY" }); }, handleComplete: handleSubmit, i18nSuffix: getFilteredExemptions(exemptions).length > 0 ? "exemptions" : "empty_exemptions", decline: false, inquiry: false }), Object.entries(exemptionList(state.case)).map(function (_a) {
                var exemptionGroup = _a[0], exemption = _a[1];
                return (_jsxs("div", { className: "flex-col space-y-2", children: [_jsx("h4", { className: "m-0", children: _jsx(T, { keyName: "exemptions.".concat(exemptionGroup), ns: "ccpa" }) }), _jsx("div", { className: "flex flex-col", children: Object.entries(exemption).map(function (_a) {
                                var exemptionType = _a[0], exemptionCount = _a[1];
                                return (_jsx(_Fragment, { children: __spreadArray([], Array(exemptionCount), true).map(function (_, index) { return (_jsxs("div", { children: [_jsx(FormControlLabel, { disabled: isFinished, control: _jsx(Checkbox, { checked: watch("exemptions.".concat(exemptionGroup, ".").concat(exemptionType, ".").concat(index, ".checked")), onChange: function (event) {
                                                        return setValue("exemptions.".concat(exemptionGroup, ".").concat(exemptionType, ".").concat(index, ".checked"), event.target.checked);
                                                    } }), label: _jsxs(_Fragment, { children: [_jsx("span", { className: "mr-1", children: tolgee.t({
                                                                key: "exemptions.".concat(exemptionGroup, ".").concat(exemptionType, ".").concat(index),
                                                                ns: "ccpa",
                                                            }) }), _jsx(BaseIconButton, { onClick: function () {
                                                                setModalText("exemptions.".concat(exemptionGroup, ".").concat(exemptionType, ".").concat(index));
                                                                setOpen(true);
                                                            }, size: "small", className: "self-center", children: _jsx(FontAwesomeIcon, { icon: "circle-info", color: PRIGHTER_BLUE }) })] }) }), watch("exemptions.".concat(exemptionGroup, ".").concat(exemptionType, ".").concat(index, ".checked")) && (_jsx(TextField, __assign({ className: "w-full", label: tolgee.t({ key: "comment" }), disabled: isFinished }, register("exemptions.".concat(exemptionGroup, ".").concat(exemptionType, ".").concat(index, ".comment")))))] })); }) }));
                            }) })] }));
            }), _jsx(BaseModal, { modalState: [open, setOpen], children: _jsxs("div", { className: "max-w-2xl", children: [_jsx("h4", { className: "m-0 mb-4", children: _jsx(T, { keyName: modalText, ns: "ccpa" }) }), _jsx("span", { children: _jsx(T, { keyName: "".concat(modalText, ".tooltip"), ns: "ccpa" }) })] }) })] }));
}
