/* eslint-disable @typescript-eslint/no-unused-vars */
export var faker = {
    number: {
        int: function (_) {
            if (_ === void 0) { _ = {}; }
            return 0;
        },
    },
    lorem: {
        words: function (_) {
            if (_ === void 0) { _ = 0; }
            return "";
        },
    },
    person: {
        fullName: function () { return ""; },
    },
    internet: {
        email: function () { return ""; },
        exampleEmail: function () { return ""; },
        userName: function () { return ""; },
    },
    location: {
        street: function () { return ""; },
        zipCode: function (_) {
            if (_ === void 0) { _ = ""; }
            return "";
        },
        city: function () { return ""; },
    },
    helpers: {
        arrayElement: function (_) {
            if (_ === void 0) { _ = []; }
            return "";
        },
    },
};
