import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";
import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import tolgee from "services/translation";
export default function SenderChanged(props) {
    var entityData = props.entityData, createdAt = props.createdAt;
    return (_jsx(HistoryEntryTemplate, { timeLineIcon: _jsx(FontAwesomeIcon, { icon: "right-left", color: "white", size: "lg" }), title: _jsxs(_Fragment, { children: [tolgee.t({ key: "sender_changed", ns: "dsr" }), _jsxs("span", { children: ["\"", entityData.new_sender.name, "\""] })] }), date: getBeautyDate(createdAt), processedAt: entityData.processed_at, children: _jsxs("div", { className: "flex flex-col p-4 space-y-2", children: [_jsxs("span", { children: [_jsxs("b", { children: [_jsx(T, { ns: "dsr", keyName: "new_sender" }), ":"] }), _jsxs("span", { children: [entityData.new_sender.name, ", ", _jsx("i", { children: entityData.new_sender.email })] })] }), _jsxs("span", { children: [_jsxs("b", { children: [_jsx(T, { ns: "dsr", keyName: "old_sender" }), ":"] }), _jsxs("span", { children: [entityData.old_sender.name, ", ", _jsx("i", { children: entityData.old_sender.email })] })] })] }) }));
}
