import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button } from "@mui/material";
import { isCheckFinished } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
import DeclineRequestButton from "pages/Client/Case/DSR/ClientView/Components/DeclineRequestButton";
import tolgee from "services/translation";
import { finalizeCheck, reopenCheck } from "store/thunks";
export default function ConfirmInquiry(_a) {
    var handleSendInquiry = _a.handleSendInquiry, handleComplete = _a.handleComplete, confirmButtonText = _a.confirmButtonText, i18nSuffix = _a.i18nSuffix, _b = _a.decline, decline = _b === void 0 ? true : _b, _c = _a.inquiry, inquiry = _c === void 0 ? true : _c;
    var _d = useCase(), state = _d[0], dispatch = _d[1];
    var caseData = state.case;
    var caseClosed = caseData.state === "CLOSED";
    var completeCheck = handleComplete || (function () { return finalizeCheck()(dispatch, caseData.uuid, state.ui.check); });
    var isFinished = isCheckFinished(state);
    var inquiryLabel;
    var confirmLabel;
    if (i18nSuffix) {
        inquiryLabel = "send_inquiry_".concat(i18nSuffix);
        confirmLabel = "confirm_".concat(i18nSuffix);
    }
    return (_jsxs(Box, { sx: { display: "flex", flexDirection: "row", justifyContent: "space-between", pt: 2 }, children: [_jsxs("div", { className: "space-x-2", children: [inquiry && (_jsx(Button, { onClick: handleSendInquiry, variant: "contained", disabled: isFinished, children: tolgee.t({ key: inquiryLabel, ns: "dsr" }) })), !caseClosed && !isFinished && decline && _jsx(DeclineRequestButton, { i18nSuffix: i18nSuffix })] }), _jsxs("div", { children: [_jsx(Button, { onClick: completeCheck, variant: "contained", disabled: isFinished, sx: {
                            backgroundColor: "#4ad158",
                            "&:hover": { backgroundColor: "#4b904f" },
                        }, children: confirmButtonText || tolgee.t({ key: confirmLabel, ns: "dsr" }) }), isFinished && !caseClosed && (_jsx(Button, { onClick: function () { return reopenCheck()(dispatch, caseData.uuid, state.ui.check); }, variant: "outlined", endIcon: _jsx(FontAwesomeIcon, { icon: "lock-open", className: " !text-sm" }), sx: {
                            ml: 2,
                            fontWeight: 600,
                        }, children: tolgee.t({ key: "reopen", ns: "dsr" }) }))] })] }));
}
