import { captureException } from "@sentry/react";
import axios from "axios";
import { API_BASE_ROUTE, DEV_ENV } from "const/env";
import { store } from "store";
import { notify, setError } from "store/app";
var axiosClient = axios.create({
    withCredentials: true,
    baseURL: API_BASE_ROUTE,
    noNotification: false,
    redirect: true,
    timeout: 30000,
    timeoutErrorMessage: "TIME_OUT",
});
axiosClient.interceptors.response.use(undefined, function (error) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (["ERR_NETWORK", "ETIMEDOUT", "ECONNABORTED"].includes(error.code)) {
        store.dispatch(notify({
            type: "ERROR",
            message: "There seems to be a problem with your internet. Please refresh this page and try again.",
            duration: 5,
        }));
        return null;
    }
    var reportSentry = true;
    if (error.config.redirect) {
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
            store.dispatch(setError("401"));
            reportSentry = false;
        }
        else if (((_b = error.response) === null || _b === void 0 ? void 0 : _b.status) === 403) {
            store.dispatch(setError("403"));
            reportSentry = false;
        }
        else if ("".concat((_c = error.response) === null || _c === void 0 ? void 0 : _c.status).startsWith("5")) {
            store.dispatch(setError("500"));
        }
    }
    if (!error.config.noNotification) {
        if (DEV_ENV) {
            if ("".concat((_d = error.response) === null || _d === void 0 ? void 0 : _d.status).startsWith("5")) {
                store.dispatch(notify({
                    type: "ERROR",
                    message: "500",
                    details: "500",
                    duration: 10,
                }));
                return error.response;
            }
            store.dispatch(notify({
                type: "ERROR",
                message: "'".concat(error.config.method.toUpperCase(), " ").concat(error.config.url, "': ").concat(error.message),
                details: (_e = error.response) === null || _e === void 0 ? void 0 : _e.data,
                duration: 5,
            }));
        }
        else if (((_f = error.response) === null || _f === void 0 ? void 0 : _f.status) === 422) {
            store.dispatch(notify({
                type: "DEFAULT",
                message: "Action '".concat(error.config.method.toUpperCase(), " ").concat(error.config.url, "' failed, please refresh the page"),
                details: (_g = error.response) === null || _g === void 0 ? void 0 : _g.data,
                duration: 30,
            }));
        }
        else {
            store.dispatch(notify({
                type: "ERROR",
                message: "Something went wrong",
            }));
        }
    }
    if (reportSentry) {
        captureException(error);
    }
    // eslint-disable-next-line no-console
    console.error("There seems to be an error with the following API route: '".concat(error.config.method.toUpperCase(), " ").concat(error.config.baseURL).concat(error.config.url, "'. '").concat(error.message, "'"));
    return error.response;
});
export default axiosClient;
