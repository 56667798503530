import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PreCheck from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/PreCheck";
import PreCheckReview from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/PreCheckReview";
import Handling from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/Handling";
import HandlingReview from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/HandlingReview";
import { useCase } from "pages/Client/Case/CaseContext";
import CheckSteps from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps";
export default function DSRFlow() {
    var state = useCase()[0];
    return (_jsxs("div", { className: ["PRE_CHECK", "PRE_CHECK_REVIEW", "HANDLING", "HANDLING_REVIEW"].includes(state.ui.stage) &&
            "mb-4 !p-0 !py-4 box-outerlayout", children: [state.ui.stage === "PRE_CHECK" && _jsx(PreCheck, {}), state.ui.stage === "PRE_CHECK_REVIEW" && _jsx(PreCheckReview, {}), state.ui.stage === "HANDLING" && _jsx(Handling, {}), state.ui.stage === "HANDLING_REVIEW" && _jsx(HandlingReview, {}), ["PRE_CHECK", "HANDLING"].includes(state.ui.stage) && _jsx(CheckSteps, {})] }));
}
