import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useState } from "react";
import GeoLocation from "pages/Client/Case/DSR/ClientView/Components/GeoLocation";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";
import { getClosedInquiries, getFilteredPersonalData, getGeoLocation, getIPAddress, getPersonalData, } from "helpers/case";
export default function DSGeoLocation() {
    var state = useCase()[0];
    var closedInquiries = getClosedInquiries(state.case.inquiries);
    var personalData = getPersonalData(state.case.case_actors);
    var geoLocation = getGeoLocation(closedInquiries);
    var ipAddress = getIPAddress(closedInquiries);
    var dsData = getFilteredPersonalData(personalData);
    var _a = useState(false), expanded = _a[0], setExpanded = _a[1];
    return (geoLocation && (_jsx("div", { className: "mb-4 box-outerlayout", children: _jsxs(Accordion, { expanded: expanded, onChange: function () { return setExpanded(!expanded); }, sx: {
                backgroundColor: "white",
                border: "none",
                boxShadow: "none",
                borderRadius: 0,
                padding: 0,
            }, className: "w-full p-2 ", "data-testid": "ds-location", children: [_jsx(AccordionSummary, { expandIcon: _jsx(FontAwesomeIcon, { size: "lg", icon: "angle-down" }), "aria-controls": "panel1a-content", sx: { padding: "0" }, id: "panel1a-header", children: _jsx("h2", { children: tolgee.t({ key: "ds_location_info", ns: "dsr" }) }) }), _jsx(AccordionDetails, { sx: { padding: 0 }, children: _jsx(GeoLocation, { geoLocation: geoLocation, ipAddress: ipAddress, dsData: dsData }) })] }) })));
}
