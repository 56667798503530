import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import InternalCommentModal from "pages/Client/Case/DSR/ClientView/Components/InternalCommentModal";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";
import { fetchCaseData } from "store/thunks";
export default function DSRMenu() {
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var _b = useState(false), openCommentModal = _b[0], setOpenCommentModal = _b[1];
    var _c = useState(null), anchorEl = _c[0], setAnchorEl = _c[1];
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var onReload = function () {
        fetchCaseData()(dispatch, state.case.uuid);
        handleClose();
    };
    return (_jsxs("div", { children: [_jsx(IconButton, { className: "w-12 h-12", onClick: handleClick, children: _jsx(FontAwesomeIcon, { icon: "ellipsis-v" }) }), _jsxs(Menu, { id: "basic-menu", anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                    "aria-labelledby": "basic-button",
                }, children: [_jsx(MenuItem, { onClick: function () { return setOpenCommentModal(true); }, children: tolgee.t({ key: "internal_comment", ns: "dsr" }) }), _jsx(MenuItem, { onClick: function () {
                            fetchCaseData()(dispatch, state.case.uuid);
                            handleClose();
                        }, children: "Reload" })] }), _jsx(InternalCommentModal, { handleReload: onReload, modalState: [openCommentModal, setOpenCommentModal] })] }));
}
