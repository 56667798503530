import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { generateUrl } from "helpers/case";
import { FileDownloadWithIcon } from "components";
import { getBeautyDate, getFileIcon, getFileName } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import tolgee from "services/translation/tolgee";
export default function CaseCreateInquiries(props) {
    var _a;
    var entityData = props.entityData, createdAt = props.createdAt;
    var caseUuid = useParams().caseUuid;
    return (_jsxs(HistoryEntryTemplate, { timeLineIcon: _jsx(FontAwesomeIcon, { icon: "question", color: "white", size: "lg" }), title: entityData.title, date: getBeautyDate(createdAt), processedAt: entityData.processed_at, children: [_jsx("div", { className: "p-4 space-y-2", children: (_a = entityData === null || entityData === void 0 ? void 0 : entityData.inquiries) === null || _a === void 0 ? void 0 : _a.map(function (i, idx) {
                    var _a;
                    return (_jsxs("div", { children: [i.question_i18n && (_jsx("div", { className: "flex flex-col", children: _jsxs("span", { children: [_jsx("b", { children: tolgee.t({ key: "question", ns: "dsr" }) }), ": ", tolgee.t({ key: i.question_i18n.key.toLowerCase(), ns: "dsr" })] }) })), i.question && (_jsx("div", { className: "flex flex-col", children: _jsxs("span", { children: [_jsx("b", { children: tolgee.t({ key: "question", ns: "dsr" }) }), ": ", i.question] }) })), i.answer_i18n && (_jsx("div", { className: "flex flex-col", children: _jsxs("span", { children: [_jsx("b", { children: tolgee.t({ key: "answer", ns: "dsr" }) }), ": ", tolgee.t({ key: i.answer_i18n.key.toLowerCase(), ns: "dsr" })] }) })), i.answer && (_jsx("div", { className: "flex flex-col", children: _jsxs("span", { children: [_jsx("b", { children: tolgee.t({ key: "answer", ns: "dsr" }) }), ": ", i.answer] }) })), _jsx("div", { className: "flex flex-col", children: ((_a = i.files) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsxs(_Fragment, { children: [_jsx("b", { children: tolgee.t({ key: "files", ns: "dsr" }) }), i.files.map(function (file) { return (_jsx(FileDownloadWithIcon, { icon: getFileIcon(file.mime_type), children: _jsx("a", { href: generateUrl(caseUuid, file.uuid), children: getFileName(file) }, file.uuid) }, file.uuid)); })] })) })] }, idx));
                }) }), _jsx("span", { className: "block w-full pt-3 pl-4 italic border-t text-brand-600", children: entityData.processed_at && (_jsx(_Fragment, { children: _jsxs("span", { className: "px-2 py-1 -mx-1 -my-1 rounded-md bg-brand-100 ", children: [tolgee.t({ key: "processed_at", ns: "dsr" }), getBeautyDate(entityData.processed_at), "."] }) })) })] }));
}
