import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";
import { useCallback } from "react";
import AddProcessorField from "pages/Client/Case/DSR/ClientView/Components/AddProcessorField";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";
import { fetchCaseData, finalizeCheck, reopenCheck } from "store/thunks";
import { isCheckFinished } from "helpers/case";
export default function AddProcessors() {
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var caseData = state.case;
    var handleReload = useCallback(function () { return fetchCaseData()(dispatch, caseData.uuid); }, [dispatch, caseData]);
    var requestClosed = caseData.state === "CLOSED";
    var isFinished = isCheckFinished(state);
    return (_jsxs("div", { children: [_jsx("div", { children: _jsx("div", { children: _jsxs(Box, { sx: {
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: requestClosed ? "end" : "space-between",
                            pt: 2,
                        }, children: [_jsx("div", {}), _jsxs("div", { className: "space-x-2", children: [isFinished && !requestClosed && (_jsx(Button, { onClick: function () { return reopenCheck()(dispatch, caseData.uuid, state.ui.check); }, variant: "outlined", endIcon: _jsx(FontAwesomeIcon, { icon: "lock-open", className: "!text-sm" }), sx: {
                                            ml: 2,
                                            fontWeight: 600,
                                        }, children: tolgee.t({ key: "reopen", ns: "dsr" }) })), _jsx(Button, { disabled: isFinished, onClick: function () { return finalizeCheck()(dispatch, caseData.uuid, state.ui.check); }, variant: "contained", sx: {
                                            backgroundColor: "#4ad158",
                                            "&:hover": { backgroundColor: "#4b904f" },
                                            fontWeight: 600,
                                        }, children: tolgee.t({ key: "confirm_processors_added", ns: "dsr" }) })] })] }) }) }), _jsx("div", { className: "mt-4", children: _jsx(T, { keyName: "description_processors_added", ns: "dsr" }) }), _jsxs("div", { className: "flex flex-col space-y-4 pt-2", children: [_jsxs("div", { children: [_jsx("span", { className: "mb-2", children: _jsx(T, { keyName: "description_add_processor_contractor", ns: "dsr" }) }), _jsx(AddProcessorField, { caseActors: caseData.case_actors, handleReload: handleReload, locked: !!isFinished, contactType: "CONTRACTOR" })] }), _jsxs("div", { children: [_jsx("span", { className: "mb-2", children: _jsx(T, { keyName: "description_add_processor_third_party", ns: "dsr" }) }), _jsx(AddProcessorField, { caseActors: caseData.case_actors, handleReload: handleReload, locked: !!isFinished, contactType: "THIRD_PARTY" })] })] })] }));
}
