var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useReducer, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import EmailCasesFormAdditional from "pages/Client/EmailCases/Components/EducationalMultistepForm/EmailCasesFormAdditional";
import EmailCasesFormWhat from "pages/Client/EmailCases/Components/EducationalMultistepForm/EmailCasesFormWhat/EmailCasesFormWhat";
import EmailCasesFormWhen from "pages/Client/EmailCases/Components/EducationalMultistepForm/EmailCasesFormWhen";
import EmailCasesFormWhere from "pages/Client/EmailCases/Components/EducationalMultistepForm/EmailCasesFormWhere";
import EmailCasesFormWho from "pages/Client/EmailCases/Components/EducationalMultistepForm/EmailCasesFormWho/EmailCasesFormWho";
import EmailCasesDetails from "pages/Client/EmailCases/Components/EmailCasesDetails";
import EmailCasesStepper from "pages/Client/EmailCases/Components/Stepper";
import { ccpaFormInitialValues } from "pages/Public/Dsr/Ccpa/ccpaRequestObject";
import { api } from "services";
var counterReducer = function (state, action) {
    var _a, _b, _c;
    switch (action.type) {
        case "increment":
            return __assign(__assign({}, state), (_a = {}, _a[action.step] = state[action.step] + 1, _a));
        case "decrement":
            return __assign(__assign({}, state), (_b = {}, _b[action.step] = state[action.step] !== 0 ? state[action.step] - 1 : 0, _b));
        case "reset":
            return __assign(__assign({}, state), (_c = {}, _c[action.step] = 0, _c));
        default:
            return state;
    }
};
export default function EmailCasesEducationalPage() {
    var _this = this;
    var id = useParams().id;
    var _a = useState(null), currentEmailCase = _a[0], setCurrentEmailCase = _a[1];
    var _b = useState(0), step = _b[0], setStep = _b[1];
    var _c = useReducer(counterReducer, {
        who: 0,
        what: 0,
    }), steps = _c[0], dispatch = _c[1];
    var _d = useState(), activeRequest = _d[0], setActiveRequest = _d[1];
    var methods = useForm({
        defaultValues: __assign(__assign({}, ccpaFormInitialValues), { dsrs: [] }),
    });
    var setValue = methods.setValue;
    var MAX_STEP = 5;
    var onSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
        var tempValues, checkedDSRs, updatedDsrs_1, valuesToSubmit, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    tempValues = methods.getValues();
                    checkedDSRs = tempValues.dsrs.filter(function (dsr) { return dsr.checked; });
                    updatedDsrs_1 = [];
                    checkedDSRs.forEach(function (item) {
                        if (item.type === "DSR_CCPA_ACCESS_GROUP") {
                            return item.inquiries.forEach(function (i) {
                                if (i.checked) {
                                    updatedDsrs_1.push({
                                        type: i.type,
                                        checked: i.checked,
                                        inquiries: [
                                            {
                                                answer: i.answer,
                                                question_i18n: i.question_i18n,
                                            },
                                        ],
                                    });
                                }
                            });
                        }
                        return updatedDsrs_1.push(item);
                    });
                    if (tempValues.actors[1].acting_for === "MYSELF") {
                        tempValues.actors = tempValues.actors.slice(0, 1);
                    }
                    else {
                        delete tempValues.actors[0].acting_for;
                    }
                    tempValues.actors[0].name = tempValues.identifiable_info.username;
                    tempValues.actors[0].email = tempValues.identifiable_info.email;
                    tempValues.actors[0].phone = tempValues.identifiable_info.phone;
                    tempValues.actors[0].address = tempValues.identifiable_info.address;
                    valuesToSubmit = __assign(__assign({}, tempValues), { dsrs: updatedDsrs_1, type: "DSR_CCPA", source: "EMAIL", parent_id: currentEmailCase.uuid });
                    return [4 /*yield*/, api.ccpa.createDsrCcpaCase(currentEmailCase.business.public_id, valuesToSubmit)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var nextStep = function () {
        if (step === MAX_STEP) {
            return;
        }
        setStep(function (prevStep) { return prevStep + 1; });
    };
    var previousStep = function () {
        setStep(function (prevStep) {
            if (prevStep === 0) {
                return 0;
            }
            return prevStep - 1;
        });
    };
    /* const previousStep = () => {
      setStep((prevStep) => prevStep - 1);
    }; */
    var renderStep = function (currenStep) {
        switch (currenStep) {
            case 0:
                return _jsx(EmailCasesFormWhere, { nextStep: nextStep });
            case 1:
                return (_jsx(EmailCasesFormWhat, { activeRequest: activeRequest, innerStep: steps.what, currentEmailCase: currentEmailCase, nextStep: nextStep, previousStep: previousStep, setActiveRequest: setActiveRequest, dispatch: function (type) { return dispatch({ type: type, step: "what" }); } }));
            case 2:
                return (_jsx(EmailCasesFormWho, { nextStep: nextStep, previousStep: previousStep, setStep: setStep, innerStep: steps.who, dispatch: dispatch }));
            case 3:
                return _jsx(EmailCasesFormWhen, { nextStep: nextStep, previousStep: previousStep });
            case 4:
                return _jsx(EmailCasesFormAdditional, { previousStep: previousStep });
            default:
                return _jsxs("p", { children: ["Something went wrong on step: ", currenStep] });
        }
    };
    useEffect(function () {
        (function (uuid) { return __awaiter(_this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.getCase(uuid)];
                    case 1:
                        response = _a.sent();
                        setCurrentEmailCase(response);
                        setValue("received_at", response === null || response === void 0 ? void 0 : response.created_at);
                        return [2 /*return*/];
                }
            });
        }); })(id);
    }, [id, setValue]);
    return (_jsx(FormProvider, __assign({}, methods, { children: _jsxs("form", { onSubmit: methods.handleSubmit(onSubmit), children: [_jsxs("div", { className: "flex items-center mb-8 gap-12", children: [_jsx("p", { children: "Logo" }), _jsxs("p", { className: "ml-auto text-prighterHighlight", children: ["Need help?", _jsx(Link, { to: "/#", className: "text-prighterHighlight", children: "Connect with Support" })] })] }), _jsxs("div", { className: "flex flex-col xl:flex-row gap-12 h-full   ", children: [_jsx("div", { className: "flex-1", children: currentEmailCase && (_jsx(EmailCasesDetails, { currentEmailCase: currentEmailCase, stepper: _jsx(EmailCasesStepper, { activeStep: step, setActiveStep: setStep }) })) }), _jsx("div", { className: "flex-1 overflow-auto  xl:max-h-[70vh]", children: _jsx("div", { children: renderStep(step) }) })] })] }) })));
}
