import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { T } from "@tolgee/react";
import { useEffect } from "react";
import tolgee from "services/translation";
import Certificate from "pages/Client/RepPages/components/Certificate";
import Snippet from "pages/Client/RepPages/components/Snippet";
export default function SwissRep() {
    useEffect(function () {
        document.title = tolgee.t({ key: "swissrep", ns: "swissrep" });
    }, []);
    return (_jsxs("div", { className: "flex flex-col mx-auto max-w-7xl", "data-testid": "swissrep-page", children: [_jsx("h1", { children: _jsx(T, { ns: "swissrep", keyName: "swissrep_title" }) }), _jsxs("div", { className: "space-y-4", children: [_jsx(Snippet, { type: "swissrep" }), _jsx(Certificate, { type: "swissrep" })] })] }));
}
