import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Chip, Stack, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDSRRequestFlag, getJurisdictionByType } from "helpers/case";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";
export default function Tags() {
    var state = useCase()[0];
    var _a = state.case, type = _a.type, _b = _a.tags, tags = _b === void 0 ? [] : _b;
    var title = tolgee.t({ key: "type.".concat(type === null || type === void 0 ? void 0 : type.toLowerCase()), ns: "dsr" });
    return (_jsxs(Stack, { direction: "row", spacing: 1, className: "mb-6", children: [_jsx(Chip, { label: getJurisdictionByType(type), icon: _jsx("img", { className: "pl-[2px]", alt: "".concat(getJurisdictionByType(type), " Prighter Logo"), width: 18, height: 18, src: getDSRRequestFlag(type) }) }), _jsx("div", { className: "hidden @2xl:block", children: _jsx(Chip, { sx: { cursor: "pointer" }, label: title, icon: _jsx(FontAwesomeIcon, { icon: "circle-info", size: "lg" }) }) }), _jsx("div", { className: "flex items-center @2xl:hidden", children: _jsx(Tooltip, { title: title, placement: "top-start", children: _jsx(FontAwesomeIcon, { className: "text-gray-600", icon: "circle-info", size: "xl" }) }) }), tags.map(function (i, idx) { return (_jsx(Chip, { label: tolgee.t({ key: "tag.".concat(i.name.toLowerCase()), ns: "cases" }), variant: "outlined" }, idx)); })] }));
}
