var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { T } from "@tolgee/react";
import { useLocation } from "react-router-dom";
import RightCheckbox from "pages/Public/Dsr/Ccpa/Step1/RightCheckbox";
import tolgee from "services/translation";
export default function RightsForm() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var _r = useFormContext(), register = _r.register, watch = _r.watch, setValue = _r.setValue, errors = _r.formState.errors;
    var location = useLocation();
    var pathname = location.pathname;
    var containsOptOut = pathname.includes("opt-out");
    var values = watch();
    var uncheckAccess = function () {
        setValue("dsrs.1.inquiries.0.checked", false);
        setValue("dsrs.1.inquiries.1.checked", false);
        setValue("dsrs.1.inquiries.2.checked", false);
    };
    var checkValues = function (value, index) {
        if (value === false) {
            setValue("dsrs.".concat(index, ".checked"), false);
            if (index === 1) {
                uncheckAccess();
            }
        }
        else if (index === 0) {
            setValue("dsrs.0.checked", true);
            setValue("dsrs.1.checked", false);
            uncheckAccess();
            setValue("dsrs.2.checked", false);
            setValue("dsrs.3.checked", false);
            setValue("dsrs.4.checked", false);
        }
        else if (index === 1 || index === 2) {
            setValue("dsrs.0.checked", false);
            setValue("dsrs.3.checked", false);
            setValue("dsrs.4.checked", false);
            setValue("dsrs.".concat(index, ".checked"), true);
        }
        else {
            setValue("dsrs.0.checked", false);
            setValue("dsrs.1.checked", false);
            uncheckAccess();
            setValue("dsrs.2.checked", false);
            setValue("dsrs.".concat(index, ".checked"), true);
        }
    };
    return (_jsx("div", { children: _jsxs("div", { children: [_jsx("h2", { children: _jsx(T, { keyName: "rights_form.i_would_like_to", ns: "ccpa" }) }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 mb-2" }), !containsOptOut && (_jsxs(_Fragment, { children: [_jsx(RightCheckbox, { title: tolgee.t({ key: "rights_form.deletion_request_title", ns: "ccpa" }), register: register("dsrs.0.checked"), checked: (_a = values.dsrs[0]) === null || _a === void 0 ? void 0 : _a.checked, onCheck: function () { var _a; return checkValues(!((_a = values.dsrs[0]) === null || _a === void 0 ? void 0 : _a.checked), 0); }, children: _jsx(TextField, __assign({}, register("dsrs.0.inquiries.0.answer"), { className: "w-full", label: tolgee.t({
                                    key: "rights_form.request_refers_to_following_personal_info",
                                    ns: "ccpa",
                                }), rows: 4, multiline: true, InputLabelProps: {
                                    shrink: true,
                                } })) }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 my-1" }), _jsxs(RightCheckbox, { title: tolgee.t({ key: "rights_form.access_request_title", ns: "ccpa" }), register: register("dsrs.1.checked"), checked: (_b = values.dsrs[1]) === null || _b === void 0 ? void 0 : _b.checked, onCheck: function () { var _a; return checkValues(!((_a = values.dsrs[1]) === null || _a === void 0 ? void 0 : _a.checked), 1); }, children: [_jsxs("div", { children: [_jsx(Checkbox, __assign({}, register("dsrs.1.inquiries.0.checked"), { checked: (_c = values.dsrs[1].inquiries[0]) === null || _c === void 0 ? void 0 : _c.checked })), _jsx(T, { keyName: "rights_form.categories", ns: "ccpa" }), ":", _jsxs("div", { className: "ml-6", children: [_jsx("li", { children: _jsx(T, { keyName: "rights_form.categories_of_personal_information_consumer", ns: "ccpa" }) }), _jsx("li", { children: _jsx(T, { keyName: "rights_form.categories_of_sources", ns: "ccpa" }) }), _jsx("li", { children: _jsx(T, { keyName: "rights_form.commertial_collection", ns: "ccpa" }) }), _jsx("li", { children: _jsx(T, { keyName: "rights_form.categories_of_third_parties", ns: "ccpa" }) })] })] }), _jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { className: "flex items-center", children: [_jsx(Checkbox, __assign({}, register("dsrs.1.inquiries.1.checked"), { checked: (_d = values.dsrs[1].inquiries[1]) === null || _d === void 0 ? void 0 : _d.checked })), _jsx(T, { keyName: "rights_form.my_request_concerns_specific_info", ns: "ccpa" })] }), ((_e = values.dsrs[1].inquiries[1]) === null || _e === void 0 ? void 0 : _e.checked) && (_jsx("div", { className: "mt-4", children: _jsx(TextField, __assign({}, register("dsrs.1.inquiries.1.answer"), { className: "w-full", label: tolgee.t({
                                                    key: "rights_form.my_request_concerns_following_information",
                                                    ns: "ccpa",
                                                }), rows: 4, multiline: true, InputLabelProps: {
                                                    shrink: true,
                                                } })) }))] }), _jsxs("div", { className: "flex items-center", children: [_jsx(Checkbox, __assign({}, register("dsrs.1.inquiries.2.checked"), { checked: (_f = values.dsrs[1].inquiries[2]) === null || _f === void 0 ? void 0 : _f.checked })), _jsx(T, { keyName: "rights_form.business_sells_info", ns: "ccpa" })] })] }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 my-1" }), _jsx(RightCheckbox, { title: tolgee.t({ key: "rights_form.change_request_title", ns: "ccpa" }), register: register("dsrs.2.checked"), checked: (_g = values.dsrs[2]) === null || _g === void 0 ? void 0 : _g.checked, onCheck: function () { var _a; return checkValues(!((_a = values.dsrs[2]) === null || _a === void 0 ? void 0 : _a.checked), 2); }, children: _jsxs("div", { className: "space-y-6", children: [_jsx(TextField, __assign({}, register("dsrs.2.inquiries.0.answer", { required: (_h = values.dsrs[2]) === null || _h === void 0 ? void 0 : _h.checked }), { className: "w-full", label: tolgee.t({
                                            key: "rights_form.request_related_to_following_personal_information",
                                            ns: "ccpa",
                                        }), required: true, error: !!(errors.dsrs && ((_j = errors.dsrs[2]) === null || _j === void 0 ? void 0 : _j.inquiries[0])) })), _jsx(TextField, __assign({}, register("dsrs.2.inquiries.1.answer", { required: (_k = values.dsrs[2]) === null || _k === void 0 ? void 0 : _k.checked }), { className: "w-full", label: tolgee.t({
                                            key: "rights_form.personal_information_is_wrong_or_incomplete_because",
                                            ns: "ccpa",
                                        }), required: true, error: !!(errors.dsrs && ((_l = errors.dsrs[2]) === null || _l === void 0 ? void 0 : _l.inquiries[1])) })), _jsx(TextField, __assign({}, register("dsrs.2.inquiries.2.answer", { required: (_m = values.dsrs[2]) === null || _m === void 0 ? void 0 : _m.checked }), { className: "w-full", label: tolgee.t({
                                            key: "rights_form.amend_the_personal_information_as_follows",
                                            ns: "ccpa",
                                        }), required: true, error: !!(errors.dsrs && ((_o = errors.dsrs[2]) === null || _o === void 0 ? void 0 : _o.inquiries[2])) }))] }) }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 my-1" })] })), _jsx(RightCheckbox, { title: tolgee.t({ key: "rights_form.optout_request_title", ns: "ccpa" }), register: register("dsrs.3.checked"), checked: (_p = values.dsrs[3]) === null || _p === void 0 ? void 0 : _p.checked, onCheck: function () { var _a; return checkValues(!((_a = values.dsrs[3]) === null || _a === void 0 ? void 0 : _a.checked), 3); } }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 my-1" }), _jsx(RightCheckbox, { title: tolgee.t({ key: "rights_form.limit_request_title", ns: "ccpa" }), register: register("dsrs.4.checked"), checked: (_q = values.dsrs[4]) === null || _q === void 0 ? void 0 : _q.checked, onCheck: function () { var _a; return checkValues(!((_a = values.dsrs[4]) === null || _a === void 0 ? void 0 : _a.checked), 4); } }), _jsx("hr", { className: "h-px text-gray-500 bg-gray-500 border-0 my-1" })] }) }));
}
