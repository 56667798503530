var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Chip } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { GRAY_200 } from "const/color";
import ActingForForm from "pages/Client/EmailCases/Components/EducationalMultistepForm/EmailCasesFormWho/Steps/ActingForForm";
import CapacityForm from "pages/Client/EmailCases/Components/EducationalMultistepForm/EmailCasesFormWho/Steps/CapacityForm";
import ConsumerForm from "pages/Client/EmailCases/Components/EducationalMultistepForm/EmailCasesFormWho/Steps/ConsumerForm";
import IdentificationCheck from "pages/Client/EmailCases/Components/EducationalMultistepForm/EmailCasesFormWho/Steps/IdentificationCheck";
import { buttonStyle } from "pages/Client/EmailCases/Pages/styling";
export default function EmailCasesFormWho(props) {
    var _this = this;
    var nextStep = props.nextStep, previousStep = props.previousStep, setStep = props.setStep, innerStep = props.innerStep, dispatch = props.dispatch;
    var _a = useFormContext(), watch = _a.watch, trigger = _a.trigger;
    var actingFor = watch("actors.1.acting_for") !== "MYSELF";
    var MAX_STEP = actingFor ? 4 : 3;
    var nextInnerStep = function () {
        dispatch({
            type: "increment",
            step: "who",
        });
    };
    var previousInnerStep = function () {
        dispatch({
            type: "decrement",
            step: "who",
        });
    };
    var addRequest = function () {
        dispatch({
            type: "reset",
            step: "what",
        });
        setStep(1);
    };
    var handleNextStep = function () { return __awaiter(_this, void 0, void 0, function () {
        var isValid;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, trigger()];
                case 1:
                    isValid = _a.sent();
                    if (!isValid) {
                        return [2 /*return*/];
                    }
                    console.log(isValid);
                    if (innerStep === MAX_STEP - 1) {
                        nextStep();
                    }
                    else {
                        nextInnerStep();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var renderStep = function (currenStep) {
        switch (currenStep) {
            case 0:
                return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex justify-between", children: [_jsx("h2", { className: "m-0 text-black font-fire text-[28px] font-normal", children: "Data subject is acting for" }), _jsxs("div", { className: "flex items-center gap-2 text-[#505C76] font-bold", children: ["Step", _jsx(Chip, { label: "".concat(currenStep + 1, "/").concat(MAX_STEP), color: "primary", sx: {
                                                backgroundColor: "#BAD8FF",
                                                color: "black",
                                            } })] })] }), _jsx(ActingForForm, {})] }));
            case 1:
                return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex justify-between", children: [_jsx("h2", { className: "m-0 text-black font-fire text-[28px] font-normal", children: "Information of individual who is the data subject (Consumer)" }), _jsxs("div", { className: "flex items-center gap-2 text-[#505C76] font-bold", children: ["Step", _jsx(Chip, { label: "".concat(currenStep + 1, "/").concat(MAX_STEP), color: "primary", sx: {
                                                backgroundColor: "#BAD8FF",
                                                color: "black",
                                            } })] })] }), _jsx(ConsumerForm, {})] }));
            case actingFor ? 2 : 3:
                return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex justify-between", children: [_jsx("h2", { className: "m-0 text-black font-fire text-[28px] font-normal", children: "Who is requesting the information (Capacity)" }), _jsxs("div", { className: "flex items-center gap-2 text-[#505C76] font-bold", children: ["Step", _jsx(Chip, { label: "".concat(currenStep + 1, "/").concat(MAX_STEP), color: "primary", sx: {
                                                backgroundColor: "#BAD8FF",
                                                color: "black",
                                            } })] })] }), _jsx(CapacityForm, {})] }));
            case actingFor ? 3 : 2:
                return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex justify-between", children: [_jsx("h2", { className: "m-0 text-black font-fire text-[28px] font-normal", children: "Is the Identification sufficient for the Request?" }), _jsxs("div", { className: "flex items-center gap-2 text-[#505C76] font-bold", children: ["Step", _jsx(Chip, { label: "".concat(currenStep + 1, "/").concat(MAX_STEP), color: "primary", sx: {
                                                backgroundColor: "#BAD8FF",
                                                color: "black",
                                            } })] })] }), _jsx(IdentificationCheck, { addRequest: addRequest })] }));
            default:
                return _jsxs("p", { children: ["Something went wrong on step: ", currenStep] });
        }
    };
    return (_jsxs("div", { className: "flex flex-col gap-12", children: [_jsxs("div", { className: "flex flex-col gap-3", children: [_jsx("h2", { className: "m-0 text-black font-fire text-[28px]", children: "Section 3: Who" }), renderStep(innerStep)] }), _jsxs("div", { className: "flex items-center gap-2", children: [_jsx(Button, { type: "button", variant: "contained", sx: __assign(__assign({}, buttonStyle), { backgroundColor: GRAY_200, color: "white" }), onClick: function () {
                            if (innerStep === 0) {
                                previousStep();
                            }
                            else {
                                previousInnerStep();
                            }
                        }, children: "Back" }), _jsx(Button, { type: "button", variant: "contained", sx: buttonStyle, onClick: handleNextStep, children: innerStep === MAX_STEP - 1 ? "Next Section" : "Continue" }), _jsx("span", { className: "ml-2 text-[#B6B6B6]", children: "or press Enter" }), _jsx(FontAwesomeIcon, { icon: "circle-exclamation", color: "orange", size: "lg", className: "ml-3" }), _jsx("span", { tabIndex: 0, role: "button", className: "ml-2 underline decoration-solid text-prighterdark", children: "Missing Information" })] }), _jsxs("div", { children: [_jsx("h2", { children: "Education" }), _jsx("p", { children: "Who is and can the Data Subject be" }), _jsx("p", { children: "Scope of Data Subjects" }), _jsx("h2", { children: "Examples" })] })] }));
}
