import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { T } from "@tolgee/react";
import { BaseButton } from "components";
import { isCheckFinished } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
import tolgee from "services/translation";
import { finalizeCheck, reopenCheck } from "store/thunks";
export default function InformThirdParty() {
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var caseData = state.case;
    var requestClosed = caseData.state === "CLOSED";
    var isFinished = isCheckFinished(state);
    return (_jsxs("div", { children: [_jsx("p", { children: _jsx(T, { keyName: "description.inform_third_party", ns: "ccpa" }) }), _jsxs("div", { className: "flex justify-end space-x-2", children: [isFinished && !requestClosed && (_jsx(Button, { onClick: function () { return reopenCheck()(dispatch, caseData.uuid, state.ui.check); }, variant: "outlined", endIcon: _jsx(FontAwesomeIcon, { icon: "lock-open", className: "!text-sm" }), sx: {
                            ml: 2,
                        }, children: tolgee.t({ key: "reopen", ns: "dsr" }) })), _jsxs("div", { className: "flex space-x-2", children: [_jsx(BaseButton, { disabled: isFinished, variant: "contained", className: "w-full", color: "error", onClick: function () {
                                    return finalizeCheck()(dispatch, caseData.uuid, state.ui.check, "INFORM_THIRD_PARTY_NO");
                                }, children: tolgee.t({ key: "inform_third_parties.no", ns: "ccpa" }) }), _jsx(BaseButton, { disabled: isFinished, variant: "contained", className: "w-full", color: "success", onClick: function () {
                                    return finalizeCheck()(dispatch, caseData.uuid, state.ui.check, "INFORM_THIRD_PARTY_YES");
                                }, children: tolgee.t({ key: "inform_third_parties.yes", ns: "ccpa" }) })] })] })] }));
}
