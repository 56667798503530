var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox } from "@mui/material";
import clsx from "clsx";
export default function RightCheckbox(_a) {
    var title = _a.title, children = _a.children, checked = _a.checked, register = _a.register, onCheck = _a.onCheck;
    return (_jsxs("div", { children: [_jsx("div", { className: "flex items-center text-center", children: _jsxs("button", { type: "button", onClick: onCheck, children: [_jsx(Checkbox, __assign({}, register, { checked: checked })), _jsx("span", { className: "font-bold", children: title })] }) }), _jsx("div", { className: clsx({ hidden: !checked, "p-4": checked && children }), children: children })] }));
}
