var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useDispatch } from "react-redux";
import { DSRDeclineKeys } from "const";
import BaseModal from "components/DataDisplay/Modal/BaseModal";
import tolgee from "services/translation";
import { api } from "services";
import { notify } from "store/app";
import { fetchCaseData } from "store/thunks";
import { useCase } from "pages/Client/Case/CaseContext";
var defaultValues = {
    reason: "",
    comment: "",
};
export default function DeclineRequestButton(props) {
    var _this = this;
    var _a = props.declineReasons, declineReasons = _a === void 0 ? DSRDeclineKeys : _a, i18nSuffix = props.i18nSuffix;
    var _b = useCase(), state = _b[0], dispatch = _b[1];
    var appDispatch = useDispatch();
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var _d = useForm({
        defaultValues: defaultValues,
    }), handleSubmit = _d.handleSubmit, register = _d.register, reset = _d.reset, setValue = _d.setValue, watch = _d.watch;
    var label;
    var labelSubmit;
    if (i18nSuffix) {
        label = "decline_open_".concat(i18nSuffix);
        labelSubmit = "decline_submit_".concat(i18nSuffix);
    }
    useEffect(function () {
        if (declineReasons.length === 1) {
            reset({ reason: declineReasons[0] });
        }
    }, [declineReasons, reset]);
    var reasonData = watch("reason");
    var handleDecline = function () {
        reset();
        setOpen(true);
    };
    var onSubmit = function (data) { return __awaiter(_this, void 0, void 0, function () {
        var declineData, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    declineData = {
                        reason: {
                            key: data.reason,
                            ns: "dsr",
                        },
                        comment: data.comment,
                    };
                    return [4 /*yield*/, api.declineCase(state.case.uuid, declineData)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        appDispatch(notify({ message: tolgee.t({ key: "decline_success", ns: "dsr" }), type: "SUCCESS" }));
                        reset(defaultValues);
                        setOpen(false);
                        fetchCaseData()(dispatch, state.case.uuid);
                    }
                    else {
                        appDispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: handleDecline, variant: "contained", sx: {
                    backgroundColor: "#C7102E",
                    "&:hover": { backgroundColor: "#980c23" },
                }, children: tolgee.t({ key: label !== null && label !== void 0 ? label : "decline_request", ns: "dsr" }) }), _jsx(BaseModal, { modalState: [open, setOpen], children: _jsxs("div", { className: "w-110 space-y-4", children: [_jsx("h3", { className: "text-center m-0", children: tolgee.t({ key: "decline_request", ns: "dsr" }) }), _jsxs("div", { className: "flex flex-col space-y-2", children: [_jsxs(FormControl, { children: [_jsx(InputLabel, { children: _jsx(T, { keyName: "select_reason", ns: "dsr" }) }), _jsx(Select, { value: reasonData, onChange: function (e) { return setValue("reason", e.target.value); }, children: declineReasons.map(function (i) { return (_jsx(MenuItem, { value: i, children: _jsx(T, { ns: "dsr", keyName: i }) }, i)); }) })] }), _jsx(TextField, __assign({ label: tolgee.t({ key: "comment", ns: "generic" }) }, register("comment")))] }), _jsx("div", { className: "flex justify-end pt-6", children: _jsx(Button, { sx: {
                                    backgroundColor: "#C7102E",
                                    "&:hover": { backgroundColor: "#980c23" },
                                }, disabled: !reasonData, onClick: handleSubmit(onSubmit), variant: "contained", children: tolgee.t({ key: labelSubmit !== null && labelSubmit !== void 0 ? labelSubmit : "decline_request_submit", ns: "dsr" }) }) })] }) })] }));
}
