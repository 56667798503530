import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";
import ControllerProcessorTag from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/PreCheckReviewTag/ControllerProcessorTag";
import DSIdentificationTag from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/PreCheckReviewTag/DSIdentificationTag";
import DSLocationTag from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/PreCheckReviewTag/DSLocationTag";
import { getPersonalData } from "helpers/case";
export default function PreCheckReviewTag(_a) {
    var caseData = _a.caseData, check = _a.check;
    var personalData = getPersonalData(caseData.case_actors);
    return (_jsxs("div", { className: "bg-success-300/50 border-2 border-dotted border-success-500 p-2 flex justify-between", children: [_jsxs("div", { className: "w-full pr-8", children: [_jsx("b", { children: _jsx(T, { keyName: "step_title.".concat(check.name.toLowerCase()), ns: "dsr" }) }), _jsx("br", {}), check.name === "DS_LOCATION" && _jsx(DSLocationTag, {}), check.name === "DS_IDENTIFICATION" && _jsx(DSIdentificationTag, { personalData: personalData }), check.name === "CONTROLLER_PROCESSOR" && (_jsx(ControllerProcessorTag, { decisions: check.case_decisions }))] }), _jsx("div", { className: "bg-success-500 w-6 h-6 rounded-full flex self-center   items-center justify-center", children: _jsx(FontAwesomeIcon, { icon: "check", color: "white" }) })] }));
}
