var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Modal from "@mui/material/Modal";
var divStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fafafa",
    boxShadow: "24px",
    border: "2px solid gray",
    padding: "2rem",
};
export default function BaseModal(props) {
    var modalState = props.modalState, children = props.children, className = props.className, _a = props.closeEvent, closeEvent = _a === void 0 ? function () { } : _a;
    var open = modalState[0], setOpen = modalState[1];
    var handleClose = function () {
        closeEvent();
        setOpen(false);
    };
    return (_jsx(Modal, { open: open, onClose: handleClose, disableEnforceFocus: true, children: _jsx("div", { className: className, style: __assign({}, divStyle), children: children }) }));
}
