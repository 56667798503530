var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@mui/material";
import { T } from "@tolgee/react";
import { useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import clsx from "clsx";
import tolgee from "services/translation";
import { notify } from "store/app";
import { useCase } from "pages/Client/Case/CaseContext";
import { isCheckFinished } from "helpers/case";
import { finalizeCheck, reopenCheck } from "store/thunks";
export default function ComplyButtons() {
    var _this = this;
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var caseData = state.case;
    var isFinished = isCheckFinished(state);
    var appDispatch = useDispatch();
    var _b = useState({
        doNotComply: false,
        complyPartiallyDisabled: false,
        complyPartiallyWarned: false,
        fullyComply: false,
    }), disableButtons = _b[0], setDisableButtons = _b[1];
    var watch = useFormContext().watch;
    useEffect(function () {
        function handleDisableButtons(exemptionsForm) {
            var _a, _b;
            if (!((_a = caseData.case_checks.find(function (check) { return check.name === "EXEMPTIONS"; })) === null || _a === void 0 ? void 0 : _a.closed_at) ||
                !((_b = caseData.case_checks.find(function (check) { return check.name === "RETAIN_DATA"; })) === null || _b === void 0 ? void 0 : _b.closed_at)) {
                setDisableButtons({
                    doNotComply: true,
                    complyPartiallyDisabled: true,
                    complyPartiallyWarned: true,
                    fullyComply: true,
                });
                return;
            }
            var doNotComplyDisabled = Object.values(exemptionsForm).flatMap(function (obj) {
                return Object.values(obj).flatMap(function (innerArr) {
                    return innerArr.filter(function (item) { return item.checked === true; });
                });
            }).length === 0;
            var complyPartiallyDisabled = caseData.outcome.retain_data.length === 0;
            var complyPartiallyWarned = caseData.outcome.retain_data.findIndex(function (item) { return item.reasons.length === 0; }) > -1;
            var fullyComplyDisabled = !doNotComplyDisabled || caseData.outcome.retain_data.length > 0;
            setDisableButtons({
                doNotComply: doNotComplyDisabled,
                complyPartiallyDisabled: complyPartiallyDisabled,
                complyPartiallyWarned: complyPartiallyWarned,
                fullyComply: fullyComplyDisabled,
            });
        }
        handleDisableButtons(watch().exemptions);
        var subscription = watch(function (exemptionsForm) {
            handleDisableButtons(exemptionsForm.exemptions);
        });
        return function () { return subscription.unsubscribe(); };
    }, [watch, caseData]);
    var handleSubmit = function (decision) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (decision === "DELETE_PARTIALLY_COMPLIED" && disableButtons.complyPartiallyWarned) {
                appDispatch(notify({
                    message: tolgee.t({
                        key: "you_can_not_leave_empty_reason_comment_and_partially_comply",
                        ns: "error",
                    }),
                    type: "ERROR",
                }));
                return [2 /*return*/];
            }
            finalizeCheck()(dispatch, caseData.uuid, state.ui.check, decision);
            return [2 /*return*/];
        });
    }); };
    return (_jsxs("div", { className: "flex flex-col space-y-4 mt-4", children: [_jsx(T, { keyName: "delete_comply_explanation", ns: "ccpa" }), _jsxs("div", { className: clsx(caseData.state !== "CLOSED" && !isFinished ? "flex justify-between" : "flex justify-end"), children: [_jsx("div", {}), _jsxs("div", { className: "flex space-x-2 self-end", children: [_jsx(Button, { variant: "contained", color: "error", disabled: isFinished || disableButtons.doNotComply, onClick: function () { return handleSubmit("DELETE_NOT_COMPLIED"); }, children: "Do not Comply" }), _jsx(Button, { variant: "contained", disabled: isFinished || disableButtons.complyPartiallyDisabled, onClick: function () { return handleSubmit("DELETE_PARTIALLY_COMPLIED"); }, children: "Comply Partially" }), _jsx(Button, { variant: "contained", color: "success", disabled: isFinished || disableButtons.fullyComply, onClick: function () { return handleSubmit("DELETE_FULLY_COMPLIED"); }, children: "Fully Comply" }), isFinished && caseData.state !== "CLOSED" && (_jsx(Button, { onClick: function () { return reopenCheck()(dispatch, caseData.uuid, state.ui.check); }, variant: "outlined", endIcon: _jsx(FontAwesomeIcon, { icon: "lock-open", className: " !text-sm" }), children: tolgee.t({ key: "reopen", ns: "dsr" }) }))] })] })] }));
}
