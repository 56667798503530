import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { T } from "@tolgee/react";
import { isCheckFinished } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
import tolgee from "services/translation";
import { finalizeCheck, reopenCheck } from "store/thunks";
export default function DSDataUpload() {
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var requestClosed = state.case.state === "CLOSED";
    var isFinished = isCheckFinished(state);
    return (_jsxs("div", { children: [_jsx("p", { children: _jsx(T, { keyName: "description.ds_data_upload", ns: "ccpa" }) }), _jsxs("div", { className: "flex justify-end space-x-2", children: [_jsx(Button, { onClick: function () { return finalizeCheck()(dispatch, state.case.uuid, state.ui.check); }, variant: "contained", disabled: isFinished, children: tolgee.t({ key: "confirm", ns: "dsr" }) }), isFinished && !requestClosed && (_jsx(Button, { onClick: function () { return reopenCheck()(dispatch, state.case.uuid, state.ui.check); }, variant: "outlined", endIcon: _jsx(FontAwesomeIcon, { icon: "lock-open", className: "!text-sm" }), sx: {
                            ml: 2,
                            fontWeight: 600,
                        }, children: tolgee.t({ key: "reopen", ns: "dsr" }) }))] })] }));
}
