import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { VerticalDataValue } from "components";
import { getPersonalData, getSenderData } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
import ConfirmInquiry from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/ConfirmInquiry";
import tolgee from "services/translation";
export default function DSIdentification() {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var _l = useCase(), state = _l[0], dispatch = _l[1];
    var caseData = state.case;
    var personalData = getPersonalData(caseData.case_actors);
    var sender = getSenderData(caseData.case_actors);
    var data = [
        {
            key: "ds_name",
            value: (_a = personalData === null || personalData === void 0 ? void 0 : personalData.contact) === null || _a === void 0 ? void 0 : _a.name,
        },
        {
            key: "email_address",
            value: (_c = (_b = personalData === null || personalData === void 0 ? void 0 : personalData.contact) === null || _b === void 0 ? void 0 : _b.contact_points.find(function (i) { return i.type === "EMAIL"; })) === null || _c === void 0 ? void 0 : _c.value,
        },
        {
            key: "phone_number",
            value: (_e = (_d = personalData === null || personalData === void 0 ? void 0 : personalData.contact) === null || _d === void 0 ? void 0 : _d.contact_points.find(function (i) { return i.type === "PHONE"; })) === null || _e === void 0 ? void 0 : _e.value,
        },
        {
            key: "acting_for",
            value: ((_f = caseData.inquiries.find(function (i) { return i.question_i18n.key === "acting_for"; })) === null || _f === void 0 ? void 0 : _f.answer_i18n.key) &&
                tolgee.t({
                    key: (_g = caseData.inquiries.find(function (i) { return i.question_i18n.key === "acting_for"; })) === null || _g === void 0 ? void 0 : _g.answer_i18n.key,
                    ns: "ccpa",
                }),
        },
    ].filter(function (i) { return !!i.value; });
    var senderData = [
        {
            key: "sender_name",
            value: (_h = sender === null || sender === void 0 ? void 0 : sender.contact) === null || _h === void 0 ? void 0 : _h.name,
        },
        {
            key: "email_address",
            value: (_k = (_j = sender === null || sender === void 0 ? void 0 : sender.contact) === null || _j === void 0 ? void 0 : _j.contact_points.find(function (i) { return i.type === "EMAIL"; })) === null || _k === void 0 ? void 0 : _k.value,
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "grid grid-cols-2 gap-4 ", children: [_jsxs("div", { className: "col-span-2 ", children: [_jsx(ConfirmInquiry, { handleSendInquiry: function () {
                                    return dispatch({
                                        type: "SEND_INQUIRY",
                                        payload: ["identification_1"],
                                    });
                                }, i18nSuffix: "ds_identification" }), _jsx("div", { className: "mt-4", children: tolgee.t({
                                    key: "description_ds_identification",
                                    ns: "dsr",
                                }) })] }), data
                        ? data === null || data === void 0 ? void 0 : data.map(function (i, idx) { return (_jsx(VerticalDataValue, { title: tolgee.t({ key: i.key, ns: "dsr" }), value: i.value }, idx)); })
                        : tolgee.t({ key: "no_id_provided", ns: "dsr" })] }), !!sender && (_jsxs(_Fragment, { children: [_jsx("h3", { className: "mt-2 mb-1", children: tolgee.t({ key: "sender_data", ns: "dsr" }) }), _jsx("div", { className: "grid  @lg:grid-cols-2 gap-x-2 gap-y-2 border  border-slate-200 rounded-lg -mx-2 px-3 py-3 ", children: senderData.map(function (i, idx) { return (_jsx(VerticalDataValue, { title: tolgee.t({ key: i.key, ns: "dsr" }), value: i.value }, idx)); }) })] }))] }));
}
