import { jsx as _jsx } from "react/jsx-runtime";
import Timeline from "@mui/lab/Timeline";
import { Fragment } from "react";
import { captureMessage } from "@sentry/react";
import { DefaultEntry, CaseCreated, CaseFinished, CaseAdvanced, CaseDeclined, CaseCreateInquiries, CaseExtendDeadline, CaseAnswerInquiries, FileAdded, EmailVerified, EmailReceived, SenderChanged, StageChanged, NewEmail, InternalComment, CaseClosed, NewState, } from "pages/Client/History/HistoryTemplates";
import tolgee from "services/translation";
import CaseFinalizePreview from "pages/Client/History/HistoryTemplates/CaseFinalizePreview";
import { useCase } from "pages/Client/Case/CaseContext";
export default function CaseHistory(props) {
    var _a;
    var propsCase = props.caseData;
    var state = useCase()[0];
    var caseData = propsCase || state.case;
    var getHistoryTemplate = function (historyItem) {
        switch (historyItem.template.name) {
            case "case.new_ccpa_dsr":
                return (_jsx(CaseCreated, { caseUuid: caseData.uuid, entityData: historyItem.content, createdAt: historyItem.created_at }));
            case "case.check_finished":
                return (_jsx(CaseFinished, { entityData: historyItem.content, createdAt: historyItem.created_at }));
            case "case.stage_advanced":
                return (_jsx(CaseAdvanced, { entityData: historyItem.content, createdAt: historyItem.created_at }));
            case "case.declined":
                return (_jsx(CaseDeclined, { entityData: historyItem.content, createdAt: historyItem.created_at }));
            case "case.create_inquiries":
                return (_jsx(CaseCreateInquiries, { entityData: historyItem.content, createdAt: historyItem.created_at }));
            case "case.extend_deadline":
                return (_jsx(CaseExtendDeadline, { entityData: historyItem.content, createdAt: historyItem.created_at }));
            case "case.answer_inquiries":
                return (_jsx(CaseAnswerInquiries, { entityData: historyItem.content, createdAt: historyItem.created_at }));
            case "authority.files_added":
                return (_jsx(FileAdded, { caseUuid: caseData.uuid, entityData: historyItem.content, createdAt: historyItem.created_at }));
            case "case.email_verified":
                return (_jsx(EmailVerified, { entityData: historyItem.content, createdAt: historyItem.created_at }));
            case "authority.email_communication":
                return (_jsx(EmailReceived, { caseUuid: caseData.uuid, entityData: historyItem.content, createdAt: historyItem.created_at }));
            case "authority.stage_changed":
                return (_jsx(StageChanged, { entityData: historyItem.content, createdAt: historyItem.created_at }));
            case "authority.change_sender":
                return (_jsx(SenderChanged, { entityData: historyItem.content, createdAt: historyItem.created_at }));
            case "authority.internal_comment":
                return (_jsx(InternalComment, { entityData: historyItem.content, createdAt: historyItem.created_at }));
            case "case.new_email":
                return (_jsx(NewEmail, { caseUuid: caseData.uuid, entityData: historyItem.content, createdAt: historyItem.created_at }));
            case "case.new_state":
                return (_jsx(NewState, { entityData: historyItem.content, createdAt: historyItem.created_at }));
            case "case.authority.closed":
            case "case.check_closed":
                return (_jsx(CaseClosed, { entityData: historyItem.content, createdAt: historyItem.created_at }));
            case "case.finalize_preview":
                return (_jsx(CaseFinalizePreview, { entityData: historyItem.content, createdAt: historyItem.created_at }));
            default:
                captureMessage("".concat(caseData.type, ": ").concat(tolgee.t({ key: "no_casebox_template", ns: "cases" }), " \"").concat(historyItem.template.name, "\""));
                return (_jsx(DefaultEntry, { entityData: historyItem.content, createdAt: historyItem.created_at }));
        }
    };
    return (_jsx("div", { className: "w-full", children: _jsx(Timeline, { position: "right", children: (_a = caseData === null || caseData === void 0 ? void 0 : caseData.histories) === null || _a === void 0 ? void 0 : _a.map(function (historyItem) { return (_jsx(Fragment, { children: getHistoryTemplate(historyItem) }, historyItem.created_at + historyItem.template.name)); }) }) }));
}
