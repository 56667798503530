var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField } from "@mui/material";
import { caseRequests } from "pages/Client/EmailCases/Components/EducationalMultistepForm/EmailCasesFormWhat/EmailCasesFormWhat";
import { multilineStyle } from "pages/Client/EmailCases/Pages/styling";
function RequestDetails(props) {
    var _a;
    var activeRequest = props.activeRequest, setActiveRequest = props.setActiveRequest;
    if (!activeRequest) {
        return null;
    }
    return (_jsx("div", { className: "flex flex-col gap-3 text-prighterblue font-normal", children: _jsxs("div", { className: "flex flex-col gap-4", children: [_jsxs("div", { className: "w-full text-sm p-3 items-center rounded-[12px] h-12 gap-3 bg-[#BAD8FF] flex  ", children: [_jsx(FontAwesomeIcon, { icon: "check-circle", color: "gray", size: "lg" }), (_a = caseRequests.find(function (r) { return r.key === activeRequest.type; })) === null || _a === void 0 ? void 0 : _a.value] }), _jsx(TextField
                // label="Multiline"
                , { 
                    // label="Multiline"
                    label: "Data Subject reason for request...", sx: multilineStyle, value: activeRequest.inquiries[0].answer, onChange: function (e) {
                        setActiveRequest(function (prev) {
                            return __assign(__assign({}, prev), { inquiries: [__assign(__assign({}, prev.inquiries[0]), { answer: e.target.value })] });
                        });
                    }, multiline: true, rows: 3 })] }) }));
}
export default RequestDetails;
