import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useState } from "react";
import CaseHistory from "pages/Client/History/CaseHistory";
import tolgee from "services/translation";
export default function DSRHistory() {
    var _a = useState(true), expanded = _a[0], setExpanded = _a[1];
    return (_jsx("div", { className: "box-outerlayout", children: _jsxs(Accordion, { expanded: expanded, onChange: function () { return setExpanded(!expanded); }, sx: {
                backgroundColor: "white",
                border: "none",
                boxShadow: "none",
                borderRadius: 0,
                padding: 0,
            }, className: "w-full p-2 ", "data-testid": "case-history", children: [_jsx(AccordionSummary, { expandIcon: _jsx(FontAwesomeIcon, { size: "lg", icon: "angle-down" }), "aria-controls": "panel1a-content", sx: { padding: "0" }, id: "panel1a-header", children: _jsx("h2", { children: tolgee.t({ key: "dsr_history", ns: "dsr" }) }) }), _jsx(AccordionDetails, { sx: { padding: 0 }, children: _jsx(CaseHistory, {}) })] }) }));
}
