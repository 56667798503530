import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { T } from "@tolgee/react";
import clsx from "clsx";
import { useEffect, useState } from "react";
export default function StatCard(_a) {
    var stats = _a.stats;
    var _b = useState(0), landingPageVisitChange = _b[0], setLandingPageVisitChange = _b[1];
    useEffect(function () {
        var visits = stats.landing_page_visits;
        if (visits.last_31_60_days !== 0 && visits.last_30_days !== 0) {
            var changes = ((visits.last_30_days - visits.last_31_60_days) / visits.last_31_60_days) * 100;
            setLandingPageVisitChange(Math.round(((changes + Number.EPSILON) * 100) / 100));
        }
    }, [stats]);
    return (_jsx("div", { className: "flex w-full md:w-6/12", "data-testid": "stats-section", children: _jsxs("div", { className: "w-full md:w-auto flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-0 md:space-x-4", children: [_jsxs("div", { className: "box-innerlayout p-4 w-full md:w-[12rem] flex flex-col items-center text-center", children: [_jsxs("div", { className: "text-4xl font-bold text-gray-900 flex", children: [stats.landing_page_visits.all_time, landingPageVisitChange !== 0 && (_jsxs("span", { className: clsx("text-sm", {
                                        "text-success-400": landingPageVisitChange > 0,
                                        "text-danger-400": landingPageVisitChange < 0,
                                    }), children: [landingPageVisitChange, "%"] }))] }), _jsx("div", { className: "text-sm font-medium text-gray-500", children: _jsx(T, { keyName: "clp_visitors", ns: "dashboard" }) })] }), _jsxs("div", { className: "box-innerlayout p-4 w-full md:w-[12rem] flex flex-col items-center text-center", children: [_jsx("div", { className: "text-4xl font-bold text-gray-900", children: stats.open_dsrs }), _jsx("div", { className: "text-sm font-medium text-gray-500", children: _jsx(T, { keyName: "open_requests", ns: "dashboard" }) })] })] }) }));
}
