import { API_BASE_ROUTE, PRIGHTER_URL } from "const/env";
export function getUrl(type, path, params) {
    var finalPath = path;
    if (type === "API" && API_BASE_ROUTE.endsWith("/api")) {
        finalPath = "/api".concat(path);
    }
    var route = new URL(finalPath, type === "API" ? API_BASE_ROUTE : PRIGHTER_URL);
    if (params) {
        route.search = params.toString();
    }
    return route.toString();
}
export function getFileIcon(type) {
    var fileType = type.toLowerCase();
    switch (true) {
        case fileType.startsWith("image"):
            return "file-image";
        case fileType.startsWith("video"):
            return "file-video";
        case fileType.startsWith("text"):
            return "file-text";
        case fileType.includes("pdf"):
            return "file-pdf";
        case fileType.includes("word"):
            return "file-word";
        case ["zip", "tar", "rar"].some(function (el) { return fileType.includes(el); }):
            return "file-zipper";
        case fileType.includes("excel"):
            return "file-excel";
        default:
            return "file";
    }
}
export function getBeautyDateTime(dateTimeRaw) {
    if (!dateTimeRaw) {
        return "No Date";
    }
    return new Date(dateTimeRaw).toLocaleString(undefined, {
        hour12: false,
    });
}
export function getBeautyDate(dateTimeRaw, long) {
    if (!dateTimeRaw) {
        return "No Date";
    }
    if (long) {
        return new Date(dateTimeRaw).toLocaleDateString(undefined, {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    }
    return new Date(dateTimeRaw).toLocaleDateString();
}
export function getImgSrc(src) {
    return "".concat(PRIGHTER_URL).concat(src);
}
export function reorderArrayElements(from, to, array) {
    array.splice(to, 0, array.splice(from, 1)[0]);
}
export function getCountryAsOptions(countries) {
    return countries.map(function (country) { return ({
        key: country.iso_3166_1_alpha_2,
        label: country.flag ? "".concat(country.flag, " ").concat(country.name) : country.name,
    }); });
}
export function objectDeepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
}
export function snakeCaseToSentenceCase(word) {
    return word
        .split("_")
        .map(function (state) { return state.charAt(0).toUpperCase() + state.slice(1); })
        .join(" ");
}
export function getFileName(file) {
    var _a;
    return ((_a = file === null || file === void 0 ? void 0 : file.name_original) === null || _a === void 0 ? void 0 : _a.length) > 0 ? "".concat(file.name, " (").concat(file.name_original, ")") : file.name;
}
export function isEmpty(object) {
    return Object.values(object).every(function (x) { return x === null || x === ""; });
}
export function redirectToFlaskUrl(url) {
    window.location.assign(getUrl("FLASK", url));
}
export var getInputValue = function (dict, key) {
    return dict && dict[key] ? dict[key] : null;
};
export var isString = function (value) {
    return typeof value === "string" || value instanceof String;
};
export var isStringEmpty = function (value) {
    return !value || (isString(value) && value && value.trim() === "");
};
