var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { captureException } from "@sentry/react";
import { T } from "@tolgee/react";
import { api } from "services";
import { notify } from "store/app";
import { useAppDispatch } from "store";
import { BaseButton, FormFileField, HorizontalDataValue } from "components";
import { getCaseControllerProcessorDecision } from "helpers/case";
import tolgee from "services/translation";
import { getBeautyDateTime } from "helpers";
import MarkdownEditor from "components/Input/MarkdownEditor";
import { useCase } from "pages/Client/Case/CaseContext";
import { fetchCaseData } from "store/thunks";
var defaultValues = {
    subject: "",
    content: "",
    files: [],
};
export default function HandlingReview() {
    var _this = this;
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var caseData = state.case;
    var appDispatch = useAppDispatch();
    var _b = useForm({
        defaultValues: defaultValues,
    }), reset = _b.reset, watch = _b.watch, setValue = _b.setValue, getValues = _b.getValues, register = _b.register;
    var textData = watch("content");
    var files = watch("files");
    var requestClosed = caseData.state === "CLOSED";
    useEffect(function () {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.ccpa.getCcpaPreview(caseData.uuid)];
                        case 1:
                            data = _a.sent();
                            reset({
                                content: data.content,
                                subject: data.subject,
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (requestClosed) {
            reset({
                content: caseData.outcome.email_content,
                subject: caseData.outcome.email_subject,
            });
        }
        else {
            fetchData();
        }
    }, [caseData.uuid, reset]);
    var onEditorStateChange = function (editorState) {
        setValue("content", editorState, { shouldDirty: true });
    };
    var finalizeReview = function () { return __awaiter(_this, void 0, void 0, function () {
        var form, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    form = getValues();
                    return [4 /*yield*/, api.finalizeCase(caseData.uuid, {
                            subject: form.subject,
                            content: form.content,
                            files: form.files || [],
                        })];
                case 1:
                    res = _a.sent();
                    if (res) {
                        appDispatch(notify({
                            message: tolgee.t({ key: "check_finished_successfully", ns: "dsr" }),
                            type: "SUCCESS",
                        }));
                        fetchCaseData()(dispatch, caseData.uuid);
                    }
                    else {
                        appDispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleUploadFiles = function (fileList) {
        var fileArray = Array.from(fileList);
        var ccpaFileArray = fileArray.map(function (file) {
            return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.onloadend = function () {
                    var base64String = reader.result;
                    resolve(base64String);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });
        Promise.all(ccpaFileArray)
            .then(function (resolvedFileArray) {
            setValue("files", resolvedFileArray.map(function (f, ind) { return ({ name: fileArray[ind].name, content: f }); }));
        })
            .catch(function (error) {
            captureException(error);
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "grid gap-1 px-6 mb-3", children: [_jsx("h2", { children: tolgee.t({ key: "handling_review_title", ns: "dsr" }) }), _jsxs("div", { children: [tolgee.t({ key: "handling_review_description", ns: "dsr" }), _jsxs("div", { className: "flex flex-col space-y-4 mt-4", children: [_jsx(TextField, __assign({ disabled: requestClosed, label: "Subject" }, register("subject"), { InputLabelProps: { shrink: true } })), _jsx(MarkdownEditor, { readOnly: requestClosed, onChange: onEditorStateChange, value: textData }), _jsx(FormFileField, { label: "Upload a file", className: "mt-2", value: files, onChange: handleUploadFiles, multiple: true, fullWidth: true, required: true, disabled: requestClosed }), requestClosed && (_jsx(HorizontalDataValue, { title: tolgee.t({ key: "email_send_at", ns: "ccpa" }), value: getBeautyDateTime(caseData.outcome.email_sent_at) }))] })] })] }), _jsxs("div", { className: "flex justify-between px-6", children: [_jsx(BaseButton, { onClick: function () { return dispatch({ type: "UPDATE_UI_STAGE", payload: "HANDLING" }); }, children: _jsx(T, { keyName: "back", ns: "generic" }) }), _jsxs(Button, { disabled: requestClosed, onClick: finalizeReview, variant: "contained", color: "success", sx: {
                            backgroundColor: "#4ad158",
                            "&:hover": { backgroundColor: "#4b904f" },
                            fontWeight: 600,
                        }, children: [getCaseControllerProcessorDecision(caseData.case_checks) === "CONTROLLER"
                                ? tolgee.t({ key: "send_and_close.controller", ns: "dsr" })
                                : tolgee.t({ key: "send_and_close.processor", ns: "dsr" }), _jsx(FontAwesomeIcon, { className: "ml-2", icon: "arrow-right", size: "lg" })] })] })] }));
}
