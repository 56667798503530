import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { T } from "@tolgee/react";
import { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import tolgee from "services/translation";
import Certificate from "pages/Client/RepPages/components/Certificate";
export default function NisRepresentation() {
    var _a = useState(true), expandedEU = _a[0], setExpandedEU = _a[1];
    var _b = useState(false), expandedUK = _b[0], setExpandedUK = _b[1];
    var search = useLocation().search;
    useEffect(function () {
        var query = new URLSearchParams(search);
        var openParam = query.get("open");
        if (openParam === "nisuk") {
            setExpandedUK(true);
            setExpandedEU(false);
        }
    }, [search]);
    useEffect(function () {
        document.title = tolgee.t({ key: "nis", ns: "nis" });
    }, []);
    return (_jsx("div", { className: "flex flex-col mx-auto max-w-7xl", "data-testid": "nis-page", children: _jsxs("div", { className: "space-y-4", children: [_jsxs(Accordion, { "data-testid": "niseu", expanded: expandedEU, onChange: function () { return setExpandedEU(!expandedEU); }, children: [_jsx(AccordionSummary, { expandIcon: _jsx(FontAwesomeIcon, { size: "lg", icon: "angle-down" }), "aria-controls": "panel1a-content", id: "panel1a-header", children: _jsx("h2", { className: "ml-6", children: _jsxs("div", { className: "flex space-x-2 items-center", children: [_jsx("div", { className: "text-4xl", children: "\uD83C\uDDEA\uD83C\uDDFA" }), _jsx("div", { children: _jsx(T, { keyName: "nis_eu", ns: "nis" }) })] }) }) }), _jsx(AccordionDetails, { children: _jsx(Certificate, { type: "niseu" }) })] }), _jsxs(Accordion, { "data-testid": "nisuk", expanded: expandedUK, onChange: function () { return setExpandedUK(!expandedUK); }, children: [_jsx(AccordionSummary, { expandIcon: _jsx(FontAwesomeIcon, { size: "lg", icon: "angle-down" }), "aria-controls": "panel1a-content", id: "panel1a-header", children: _jsx("h2", { className: "ml-6", children: _jsxs("div", { className: "flex space-x-2 items-center", children: [_jsx("div", { className: "text-4xl", children: "\uD83C\uDDEC\uD83C\uDDE7" }), _jsx("div", { children: _jsx(T, { keyName: "nis_uk", ns: "nis" }) })] }) }) }), _jsx(AccordionDetails, { children: _jsx(Certificate, { type: "nisuk" }) })] })] }) }));
}
