var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { categoriesOfPersonalData, ccpaRetainDataReasonCount, exemptions, specificExemptions, } from "const/dsr";
export function serializeExemptionForm(exemptionForm, exemptionData) {
    var defaultValues = Object.entries(exemptionForm).reduce(function (acc, _a) {
        var exemptionGroup = _a[0], exemptionValues = _a[1];
        acc[exemptionGroup] = Object.entries(exemptionValues).reduce(function (innerAcc, _a) {
            var _b;
            var exemptionType = _a[0], exemptionAmount = _a[1];
            var transformedValue = Array.from({ length: exemptionAmount }, function (_, ind) { return ({
                checked: false,
                name: "exemptions.".concat(exemptionGroup, ".").concat(exemptionType, ".").concat(ind),
                reason: "",
                comment: "",
            }); });
            var updatedInnerAcc = __assign(__assign({}, innerAcc), (_b = {}, _b[exemptionType] = transformedValue, _b));
            return updatedInnerAcc;
        }, {});
        return acc;
    }, {});
    exemptionData.forEach(function (ed) {
        var e = ed.name.split(".");
        defaultValues[e[1]][e[2]][e[3]].checked = true;
        defaultValues[e[1]][e[2]][e[3]].comment = ed.comment;
    });
    return defaultValues;
}
export function serializeRetainDataForm(retainData) {
    var defaultValues = Object.entries(categoriesOfPersonalData).reduce(function (acc, _a) {
        var category = _a[0], categoryAmount = _a[1];
        acc[category] = __spreadArray([], Array(categoryAmount), true).map(function (_, ind) { return ({
            checked: false,
            name: "categories_of_personal_info.".concat(category, ".").concat(ind),
            reasons: Array.from({ length: ccpaRetainDataReasonCount }, function () { return ({
                name: "",
            }); }),
            comment: "",
        }); });
        return acc;
    }, {});
    retainData.forEach(function (rd) {
        var r = rd.name.split(".");
        defaultValues[r[1]][r[2]].checked = true;
        defaultValues[r[1]][r[2]].comment = rd.comment;
        rd.reasons.forEach(function (reason) {
            defaultValues[r[1]][r[2]].reasons[reason.split(".")[reason.split(".").length - 1]].name =
                reason;
        });
    });
    return defaultValues;
}
export function serializeExemptionRetainDataForm(exemptionForm, exemptionData, retainData) {
    return {
        exemptions: serializeExemptionForm(exemptionForm, exemptionData),
        retainData: serializeRetainDataForm(retainData),
    };
}
export var exemptionList = function (caseData) {
    var _a;
    return (_a = {
            generic: exemptions
        },
        _a[caseData.type] = specificExemptions[caseData.type],
        _a);
};
