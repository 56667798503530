var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { T } from "@tolgee/react";
import { Chip } from "components";
import { notify } from "store/app";
import { api } from "services";
import CompanyDetails from "pages/Client/Company/Components/CompanyDetails";
import CompanySubscriptions from "pages/Client/Company/Components/CompanySubscriptions";
import tolgee from "services/translation";
export default function CompanyData() {
    var _this = this;
    var businessId = useParams().businessId;
    var dispatch = useDispatch();
    var _a = useState(), data = _a[0], setData = _a[1];
    var _b = useState(), configData = _b[0], setConfigData = _b[1];
    useEffect(function () {
        document.title = tolgee.t({ key: "company_profile", ns: "billing" });
        fetchData();
        fetchConfigData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessId]);
    var fetchData = function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.company.getCompany(businessId)];
                case 1:
                    res = _a.sent();
                    if (!res) {
                        dispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                        return [2 /*return*/];
                    }
                    setData(res);
                    return [2 /*return*/];
            }
        });
    }); };
    var fetchConfigData = function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.clp.getClpSettings(businessId)];
                case 1:
                    res = _a.sent();
                    if (!res) {
                        dispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                        return [2 /*return*/];
                    }
                    setConfigData(res);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: "flex flex-col mx-auto max-w-7xl", children: [_jsxs("div", { className: "box-outerlayout mb-4", children: [_jsxs("div", { className: "flex flex-col md:flex-row align-center", children: [_jsxs("h1", { children: [_jsx(T, { ns: "billing", keyName: "company" }), " ", data === null || data === void 0 ? void 0 : data.company_name] }), (data === null || data === void 0 ? void 0 : data.demo) && (_jsx("div", { className: "mt-4 sm:ml-4", children: _jsx(Chip, { title: tolgee.t({ key: "demo_account", ns: "billing" }) }) }))] }), data && (_jsx(CompanyDetails, { onAddressUpdate: function () { return fetchData(); }, onLinkUpdate: function () { return fetchConfigData(); }, companyData: data, configData: configData }))] }), _jsxs("div", { className: "box-outerlayout", children: [_jsx("h2", { children: _jsx(T, { ns: "billing", keyName: "subscriptions_assigned" }) }), data && _jsx(CompanySubscriptions, { companyData: data })] })] }));
}
