var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BaseTextField, ExtendedSelectField } from "components/Input";
import { getCountryAsOptions } from "helpers";
import { useAppSelector } from "store";
import { PRIGHTER_BLUE } from "const/color";
import tolgee from "services/translation";
export default function ContactAddress(_a) {
    var _b = _a.contactAddresses, contactAddresses = _b === void 0 ? [] : _b, onChange = _a.onChange;
    var _c = useState([]), countryOptions = _c[0], setCountryOptions = _c[1];
    var countries = useAppSelector(function (state) { return state.app; }).countries;
    useEffect(function () {
        if (countries) {
            var options = getCountryAsOptions(countries);
            setCountryOptions(options);
        }
    }, [countries]);
    var handleChange = function (index, objKey, value) {
        var tempPrev = __spreadArray([], contactAddresses, true);
        tempPrev[index][objKey] = value;
        onChange(tempPrev);
    };
    var handleRemove = function (index) {
        var tempContactAddresses = __spreadArray([], contactAddresses, true);
        tempContactAddresses.splice(index, 1);
        onChange(__spreadArray([], tempContactAddresses, true));
    };
    return (_jsx("div", { className: "flex flex-col space-y-4", children: contactAddresses.map(function (contactAddress, ind) { return (_jsxs("div", { className: "flex items-center space-x-4", children: [_jsx(FontAwesomeIcon, { icon: "location-dot", color: PRIGHTER_BLUE, className: "w-14" }), _jsx(BaseTextField, { value: contactAddress.postal, onChange: function (text) { return handleChange(ind, "postal", text.target.value); }, label: tolgee.t({ key: "postal", ns: "generic" }), containerClassName: "w-24" }), _jsx(BaseTextField, { value: contactAddress.city, onChange: function (text) { return handleChange(ind, "city", text.target.value); }, label: tolgee.t({ key: "city", ns: "generic" }), containerClassName: "w-full" }), _jsx(BaseTextField, { value: contactAddress.street, onChange: function (text) { return handleChange(ind, "street", text.target.value); }, label: tolgee.t({ key: "street", ns: "generic" }), containerClassName: "w-full", grow: true }), _jsx(ExtendedSelectField, { state: [
                        contactAddress.country_iso,
                        function (text) { return handleChange(ind, "country_iso", text); },
                    ], options: countryOptions, className: "min-w-[12rem]", label: tolgee.t({ key: "country", ns: "generic" }) }), _jsx(IconButton, { "data-testid": "remove-address-button", onClick: function () { return handleRemove(ind); }, size: "medium", children: _jsx(FontAwesomeIcon, { icon: "trash", color: "red", size: "sm" }) })] }, contactAddress.uuid || ind)); }) }));
}
