import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";
export default function ServerError() {
    useEffect(function () {
        document.title = "Server Error";
    }, []);
    return (_jsx("div", { className: "flex items-center  h-screen justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8", "data-testid": "error-element", children: _jsx("div", { className: "max-w-md w-full ", children: _jsx("div", { children: _jsxs("div", { className: "text-center", children: [_jsx("h1", { children: _jsx(T, { ns: "generic", keyName: "unexpected_error" }) }), _jsx(FontAwesomeIcon, { icon: "spinner", color: "gray", className: "fa-pulse mb-4", size: "2xl" }), _jsx("br", {}), _jsx(T, { ns: "generic", keyName: "click_refresh" }), _jsx("p", { className: "font-italic", children: _jsx(T, { ns: "generic", keyName: "contact_support" }) })] }) }) }) }));
}
