import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

import { VerticalDataValue } from "components";
import tolgee from "services/translation";

L.Icon.Default.mergeOptions({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
});

export default function GeoLocation({ geoLocation, ipAddress, dsData }) {
  const parsedGeolocation = JSON.parse(geoLocation);
  const geolocationString = `${parsedGeolocation[0]}, ${parsedGeolocation[1]}`;

  return (
    <div className="flex">
      <div className="flex flex-col space-y-2 w-1/2">
        {dsData.length > 0
          ? dsData?.map((i, idx) => (
              <VerticalDataValue
                key={idx}
                title={tolgee.t({ key: i.key, ns: "dsr" })}
                value={i.value}
              />
            ))
          : tolgee.t({ key: "no_address", ns: "dsr" })}
        {ipAddress && (
          <VerticalDataValue title={tolgee.t({ key: "ip_address", ns: "dsr" })} value={ipAddress} />
        )}
        {geoLocation && (
          <VerticalDataValue
            title={tolgee.t({ key: "location", ns: "dsr" })}
            value={geolocationString}
          />
        )}
      </div>

      <div className="h-[250px] w-1/2">
        <MapContainer
          doubleClickZoom={false}
          closePopupOnClick={false}
          dragging={false}
          zoomSnap={false}
          zoomDelta={false}
          trackResize={false}
          touchZoom={false}
          scrollWheelZoom={false}
          style={{ height: "250px", width: "auto" }}
          center={parsedGeolocation}
          zoom={13}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={parsedGeolocation} />
        </MapContainer>
      </div>
    </div>
  );
}
