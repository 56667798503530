var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export default function providerReducer(state, action) {
    switch (action.type) {
        case "LOADING":
            return __assign(__assign({}, state), { loading: true });
        case "FETCH_SUCCESS": {
            return __assign(__assign({}, state), { loading: false, case: action.payload });
        }
        case "UPDATE_UI_STAGE": {
            return __assign(__assign({}, state), { ui: __assign(__assign({}, state.ui), { stage: action.payload }) });
        }
        case "UPDATE_UI_CHECK": {
            return __assign(__assign({}, state), { ui: __assign(__assign({}, state.ui), { check: action.payload }) });
        }
        case "SEND_INQUIRY": {
            return __assign(__assign({}, state), { inquiry: { open: true, questions: action.payload || [] } });
        }
        case "CLOSE_INQUIRY_MODAL": {
            return __assign(__assign({}, state), { inquiry: { open: false, questions: [] } });
        }
        default: {
            return state;
        }
    }
}
