var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";
import api from "services/api";
import { BaseButton, BaseIconButton } from "components";
import { redirectToFlaskUrl } from "helpers";
import { useAppDispatch } from "store";
import { notify } from "store/app";
import { PRIGHTER_BLUE } from "const/color";
export default function PaymentMethods() {
    var _this = this;
    var dispatch = useAppDispatch();
    var _a = useState(0), reload = _a[0], setReload = _a[1];
    var _b = useState([]), paymentMethods = _b[0], setPaymentMethods = _b[1];
    useEffect(function () {
        document.title = "Payment Methods";
    }, []);
    useEffect(function () {
        function getPaymentMethods() {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.getPaymentMethods()];
                        case 1:
                            res = _a.sent();
                            setPaymentMethods(res);
                            return [2 /*return*/];
                    }
                });
            });
        }
        getPaymentMethods();
    }, [reload]);
    var handleAddCreditCard = function () {
        redirectToFlaskUrl("/my/payment/add_source");
    };
    var handleRemoveCreditCard = function (externalId) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.deletePaymentMethod(externalId)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        dispatch(notify({ message: res, type: "ERROR" }));
                    }
                    else {
                        dispatch(notify({ message: "Card removed successfully", type: "SUCCESS" }));
                        setReload(function (prevReload) { return prevReload + 1; });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: "box-outerlayout flex flex-col mx-auto max-w-7xl", children: [_jsx("h1", { children: _jsx(T, { ns: "generic", keyName: "payment_methods" }) }), _jsx("div", { className: "space-y-2 mb-2", children: paymentMethods.map(function (paymentMethod) { return (_jsxs("div", { className: "box-innerlayout flex justify-between items-center", children: [_jsxs("div", { className: "flex items-center space-x-2", children: [_jsx(FontAwesomeIcon, { icon: "credit-card", color: PRIGHTER_BLUE, size: "sm" }), _jsx("span", { children: paymentMethod.human_description })] }), _jsx(BaseIconButton, { confirmPopup: true, className: "self-center", onClick: function () { return handleRemoveCreditCard(paymentMethod.external_id); }, size: "medium", children: _jsx(FontAwesomeIcon, { icon: "trash", color: "red", size: "sm" }) })] })); }) }), _jsx(BaseButton, { className: "self-end", onClick: function () { return handleAddCreditCard(); }, children: _jsx(T, { ns: "generic", keyName: "new_card" }) })] }));
}
