import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBeautyDate, getFileIcon, getFileName } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import { FileDownloadWithIcon } from "components";
import tolgee from "services/translation/tolgee";
import { generateUrl } from "helpers/case";
export default function CaseCreated(props) {
    var _a, _b, _c;
    var entityData = props.entityData, createdAt = props.createdAt, caseUuid = props.caseUuid;
    return (_jsx(HistoryEntryTemplate, { timeLineIcon: _jsx(FontAwesomeIcon, { icon: "building", color: "white", size: "lg" }), title: entityData.title ? entityData.title : "NO TITLE", date: getBeautyDate(createdAt), processedAt: entityData.processed_at, children: _jsxs("div", { className: "w-full p-0 pb-8 my-4", children: [_jsxs("div", { className: "flex items-center w-full h-12 pl-4 font-bold bg-slate-200", children: [tolgee.t({ key: "from", ns: "dsr" }), _jsx("em", { className: "ml-1 text-center", children: "".concat((_a = entityData.ds_info) === null || _a === void 0 ? void 0 : _a.name, " <").concat((_b = entityData.ds_info) === null || _b === void 0 ? void 0 : _b.email, ">") })] }), _jsx("div", { className: "flex flex-row justify-between py-4", style: { paddingLeft: "40px" }, children: _jsx("div", { className: "flex flex-row", children: _jsx("div", { className: "flex flex-col pr-2", children: ((_c = entityData.files) === null || _c === void 0 ? void 0 : _c.length) > 0 && (_jsxs(_Fragment, { children: [_jsx("b", { children: tolgee.t({ key: "files", ns: "dsr" }) }), entityData.files.map(function (file) { return (_jsx(FileDownloadWithIcon, { icon: getFileIcon(file.mime_type), children: _jsx("a", { href: generateUrl(caseUuid, file.uuid), children: getFileName(file) }) }, file.uuid)); })] })) }) }) })] }) }));
}
