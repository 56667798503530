var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api } from "services";
import tolgee from "services/translation";
import { dataGridConfig } from "helpers/dataGridConfig";
import { LinkButton, SearchField } from "components";
import { useAppSelector } from "store";
import { useDebounce } from "helpers/hooks";
import { getUrl } from "helpers";
import { PRIGHTER_BLUE } from "const/color";
export default function Partners() {
    var _this = this;
    var countries = useAppSelector(function (state) { return state.app; }).countries;
    var roles = useAppSelector(function (state) { return state.user; }).roles;
    var _a = useState(""), search = _a[0], setSearch = _a[1];
    var debouncedSearchValue = useDebounce(search, 500);
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState(), data = _c[0], setData = _c[1];
    var _d = useState(0), currentPage = _d[0], setCurrentPage = _d[1];
    var _e = useState("name"), orderedBy = _e[0], setOrderedBy = _e[1];
    var _f = useState("asc"), newOrder = _f[0], setNewOrder = _f[1];
    var _g = useState(25), rowsPerPage = _g[0], setRowsPerPage = _g[1];
    useEffect(function () {
        document.title = tolgee.t({ key: "partners", ns: "partners" });
    }, []);
    var fetchData = function (props) { return __awaiter(_this, void 0, void 0, function () {
        var orderBy, page, perPage, order, searchTerm, desc, res, tableData;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoading(true);
                    orderBy = props.orderBy, page = props.page, perPage = props.perPage, order = props.order, searchTerm = props.searchTerm;
                    desc = order === "desc";
                    return [4 /*yield*/, api.partner.getPartners(orderBy, page + 1, perPage, desc, searchTerm)];
                case 1:
                    res = _b.sent();
                    if (!res) {
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    tableData = __assign(__assign({}, dataGridConfig({ currentPage: currentPage, rowsPerPage: rowsPerPage, count: res.count })), { onSortModelChange: function (sortVal) {
                            if ((sortVal === null || sortVal === void 0 ? void 0 : sortVal.length) === 0) {
                                return;
                            }
                            setOrderedBy(sortVal[0].field);
                            setNewOrder(sortVal[0].sort);
                        }, onPaginationModelChange: function (val) {
                            setCurrentPage(val.page);
                            setRowsPerPage(val.pageSize);
                        }, columns: [
                            {
                                field: "name",
                                headerName: tolgee.t({ key: "name", ns: "partners" }),
                                flex: 1,
                            },
                            {
                                field: "company_name",
                                headerName: tolgee.t({ key: "company", ns: "partners" }),
                                flex: 1,
                            },
                            {
                                field: "email",
                                headerName: tolgee.t({ key: "email", ns: "partners" }),
                                flex: 1,
                            },
                            {
                                field: "street",
                                headerName: tolgee.t({ key: "address", ns: "partners" }),
                                flex: 2,
                                valueGetter: function (_, params) {
                                    var address = params.street || "";
                                    if (params.postal) {
                                        address += address ? ", ".concat(params.postal) : params.postal;
                                    }
                                    if (params.city) {
                                        address += address ? ", ".concat(params.city) : params.city;
                                    }
                                    if (params.iso_3166_1_alpha_2) {
                                        var country = countries === null || countries === void 0 ? void 0 : countries.find(function (i) { return i.iso_3166_1_alpha_2 === params.iso_3166_1_alpha_2; });
                                        if (country) {
                                            address += address
                                                ? ", ".concat(country === null || country === void 0 ? void 0 : country.flag, " ").concat(country === null || country === void 0 ? void 0 : country.name)
                                                : "".concat(country === null || country === void 0 ? void 0 : country.flag, " ").concat(country === null || country === void 0 ? void 0 : country.name);
                                        }
                                    }
                                    return address;
                                },
                            },
                            {
                                field: "stripe_customer_id",
                                headerName: tolgee.t({ key: "stripe", ns: "partners" }),
                                flex: 1,
                                renderCell: function (val) {
                                    if (val === null || val === void 0 ? void 0 : val.value) {
                                        return (_jsx("a", { href: "https://dashboard.stripe.com/customers/".concat(val === null || val === void 0 ? void 0 : val.value), children: val === null || val === void 0 ? void 0 : val.value }));
                                    }
                                    return "";
                                },
                            },
                            {
                                flex: 1,
                                sortable: false,
                                renderCell: function (params) {
                                    return (_jsxs("div", { className: "space-x-2 flex", children: [_jsx(LinkButton, { color: "NONE", href: getUrl("FLASK", "/administration/customer/".concat(params.id, "/invoices")), children: _jsx("div", { className: "w-3 h-3 flex items-center justify-center", children: _jsx(FontAwesomeIcon, { size: "lg", icon: "file-invoice-dollar", color: PRIGHTER_BLUE }) }) }), _jsx(LinkButton, { color: "NONE", href: getUrl("FLASK", "/administration/manage_customer/".concat(params.id)), children: _jsx("div", { className: "w-3 h-3 flex items-center justify-center", children: _jsx(FontAwesomeIcon, { size: "lg", icon: "user", color: PRIGHTER_BLUE }) }) }), roles.some(function (role) { return ["ADMIN", "MANAGER-GHOSTLOGIN"].includes(role.name); }) && (_jsx(LinkButton, { color: "NONE", href: getUrl("FLASK", "/administration/customer/".concat(params.id, "/ghostlogin")), children: _jsx("div", { className: "w-3 h-3 flex items-center justify-center", children: _jsx(FontAwesomeIcon, { size: "lg", icon: "ghost", color: PRIGHTER_BLUE }) }) }))] }));
                                },
                            },
                        ], rows: (_a = res === null || res === void 0 ? void 0 : res.result) === null || _a === void 0 ? void 0 : _a.map(function (i) {
                            return __assign(__assign({}, i), { id: i === null || i === void 0 ? void 0 : i.uuid });
                        }) });
                    setData(tableData);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchData({
            orderBy: orderedBy,
            page: currentPage,
            perPage: rowsPerPage,
            order: newOrder,
            searchTerm: debouncedSearchValue,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, rowsPerPage, orderedBy, newOrder, debouncedSearchValue]);
    return (_jsx("div", { className: "flex flex-col mx-auto max-w-7xl", "data-testid": "partners", children: data ? (_jsxs("div", { className: "box-outerlayout", children: [_jsx(SearchField, { className: "w-full", searchState: [search, setSearch] }), _jsx(DataGrid, __assign({}, data, { loading: loading, sx: {
                        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
                        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
                        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" },
                    } }))] })) : (!loading && _jsx("div", { children: tolgee.t({ key: "no_data", ns: "billing" }) })) }));
}
