import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-return-assign */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { T } from "@tolgee/react";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "store";
export default function ToDoList(_a) {
    var userToDos = _a.userToDos, businessTodos = _a.businessTodos;
    var _b = useState(false), showAll = _b[0], setShowAll = _b[1];
    var todosLeft = useMemo(function () {
        var count = userToDos.filter(function (i) { return i.todo === false; }).length;
        Object.entries(businessTodos).map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        function (_a) {
            var _ = _a[0], businessTodoList = _a[1];
            return (count += businessTodoList.filter(function (j) { return (j === null || j === void 0 ? void 0 : j.todo) === false; }).length);
        });
        return count;
    }, [userToDos, businessTodos]);
    var managed_businesses = useAppSelector(function (state) { return state.user; }).managed_businesses;
    var getCompanyName = function (businessId) {
        var _a;
        return ((_a = managed_businesses.find(function (b) { return b.public_id === businessId; })) === null || _a === void 0 ? void 0 : _a.company_name) || businessId;
    };
    return (_jsxs("div", { className: "box-outerlayout", "data-testid": "todos-section", children: [_jsxs("div", { className: "p-0 ".concat(!showAll && "max-h-[36rem]", " overflow-y-auto"), children: [_jsx("h2", { className: "pb-4 text-2xl border-0 border-b border-gray-200 border-solid md:px-4 text-brand-900", children: _jsx(T, { keyName: "complete_onboarding", ns: "dashboard" }) }), userToDos.length > 0 && (_jsxs("div", { className: "flex flex-col pt-0 space-y-1 md:p-4", children: [_jsx("h4", { className: "m-0", children: _jsx(T, { keyName: "account_tasks", ns: "dashboard" }) }), userToDos.map(function (todo) { return (_jsx(Link, { to: todo.action_url, style: todo.todo ? { pointerEvents: "none" } : null, className: "pr-2 hover:bg-gray-200", children: _jsxs("div", { className: "relative flex justify-between", "data-testid": "user-todo", children: [_jsx("p", { className: "md:pl-8", children: todo.title }), _jsxs("div", { className: "flex items-center space-x-4", children: [_jsx("div", { className: "hidden sm:flex sm:items-end", children: _jsx("p", { className: "text-sm leading-6 text-gray-900", children: todo.action_title }) }), _jsx(FontAwesomeIcon, { icon: "chevron-right", className: "text-gray-400", size: "lg" })] })] }) }, todo.id)); })] })), Object.entries(businessTodos).map(function (_a) {
                        var businessId = _a[0], businessTodoList = _a[1];
                        return (businessTodoList === null || businessTodoList === void 0 ? void 0 : businessTodoList.length) > 0 && (_jsxs("div", { className: "flex flex-col pt-0 space-y-1 md:p-4", "data-testid": "business-todo", children: [_jsxs("h4", { className: "m-0", children: ["Complete ", getCompanyName(businessId), "'s Onboarding"] }), businessTodoList
                                    .filter(function (t) { return !t.todo; })
                                    .map(function (todo) { return (_jsx(Link, { to: todo.action_url, style: todo.todo ? { pointerEvents: "none" } : null, className: "pr-2 hover:bg-gray-200", children: _jsxs("div", { className: "relative flex justify-between", children: [_jsx("p", { className: "md:pl-8", children: todo.title }), _jsxs("div", { className: "flex items-center space-x-4", children: [_jsx("div", { className: "hidden sm:flex sm:items-end", children: _jsx("p", { className: "text-sm leading-6 text-gray-900", children: todo.action_title }) }), _jsx(FontAwesomeIcon, { icon: "chevron-right", className: "text-gray-400", size: "lg" })] })] }) }, todo.id)); })] }, businessId));
                    })] }), todosLeft > 7 && (_jsx(Button, { onClick: function () { return setShowAll(!showAll); }, children: showAll ? _jsx(T, { keyName: "show_less", ns: "generic" }) : _jsx(T, { keyName: "show_all", ns: "generic" }) }))] }));
}
