import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";
import "react-mde/lib/styles/css/react-mde-all.css";
export default function MarkdownEditor(props) {
    var value = props.value, onChange = props.onChange, readOnly = props.readOnly;
    var _a = useState("preview"), selectedTab = _a[0], setSelectedTab = _a[1];
    var handleSelectedTab = function (tab) {
        if (readOnly) {
            return;
        }
        setSelectedTab(tab);
    };
    return (_jsx(ReactMde, { value: value, onChange: onChange, selectedTab: selectedTab, onTabChange: handleSelectedTab, minEditorHeight: 500, classes: { preview: "prose" }, readOnly: readOnly, generateMarkdownPreview: function (markdown) {
            return Promise.resolve(_jsx(ReactMarkdown, { children: markdown }));
        } }));
}
