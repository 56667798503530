var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControlLabel, IconButton, Popover, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { API_BASE_ROUTE, DEV_ENV, IS_PROD_DEV, PRIGHTER_URL, REACT_BASE_ROUTE, STRIPE_KEY, TOLGEE_KEY, } from "const/env";
import { CollapsibleInnerBox, HorizontalDataValue } from "components";
import api from "services/api";
import { useAppDispatch, useAppSelector } from "store";
import { setDevRedirection } from "store/app";
var selectReduxState = createSelector(function (state) { return (__assign(__assign({}, state), { app: __assign(__assign({}, state.app), { countries: [] }) })); }, function (state) { return state; });
export default function DevelopmentWidget() {
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var _b = useState({}), apiStatus = _b[0], setApiStatus = _b[1];
    var _c = useState(false), redirect = _c[0], setRedirect = _c[1];
    var dispatch = useAppDispatch();
    var reduxState = useAppSelector(selectReduxState);
    var handleChange = function (event) {
        setRedirect(event.target.checked);
        dispatch(setDevRedirection(event.target.checked));
    };
    useEffect(function () {
        function fetchApiStatus() {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = setApiStatus;
                            return [4 /*yield*/, api.ping()];
                        case 1:
                            _a.apply(void 0, [_b.sent()]);
                            return [2 /*return*/];
                    }
                });
            });
        }
        fetchApiStatus();
    }, []);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var open = Boolean(anchorEl);
    var id = open ? "simple-popover" : undefined;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "absolute z-50 right-5 bottom-5 flex justify-center items-center bg-white border border-gray rounded-full", children: _jsx(IconButton, { className: "w-12 h-12", onClick: handleClick, children: _jsx(FontAwesomeIcon, { icon: "code" }) }) }), _jsx(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                }, transformOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                }, children: _jsxs("div", { className: "p-4 space-y-4 h-[48rem] w-[40rem]", children: [_jsx(FormControlLabel, { control: _jsx(Switch, { checked: redirect, onChange: handleChange }), label: "Redirection" }), _jsxs("div", { className: "box-innerlayout space-y-4", children: [_jsx("h2", { className: "mt-0", children: "Environment Variables" }), _jsx(HorizontalDataValue, { title: "STRIPE_KEY", value: STRIPE_KEY }), _jsx(HorizontalDataValue, { title: "API_BASE_ROUTE", value: API_BASE_ROUTE }), _jsx(HorizontalDataValue, { title: "PRIGHTER_URL", value: PRIGHTER_URL }), _jsx(HorizontalDataValue, { title: "REACT_BASE_ROUTE", value: REACT_BASE_ROUTE }), _jsx(HorizontalDataValue, { title: "DEV_ENV", value: "".concat(DEV_ENV) }), _jsx(HorizontalDataValue, { title: "IS_PROD_DEV", value: "".concat(IS_PROD_DEV) }), TOLGEE_KEY && _jsx(HorizontalDataValue, { title: "TOLGEE_KEY", value: TOLGEE_KEY })] }), (apiStatus === null || apiStatus === void 0 ? void 0 : apiStatus.user) && (_jsx(CollapsibleInnerBox, { defaultCollapsed: true, title: _jsx("h2", { className: "m-0", children: "User Information" }), children: _jsx("pre", { className: "whitespace-pre-wrap break-words", children: JSON.stringify(apiStatus.user, null, 2) }) })), apiStatus && (_jsx(CollapsibleInnerBox, { defaultCollapsed: true, title: _jsx("h2", { className: "m-0", children: "API Status" }), children: _jsx("pre", { className: "whitespace-pre-wrap break-words", children: JSON.stringify(__assign(__assign({}, apiStatus), { user: undefined }), null, 2) }) })), _jsx(CollapsibleInnerBox, { defaultCollapsed: true, title: _jsx("h2", { className: "m-0", children: "Redux State" }), children: _jsx("pre", { className: "whitespace-pre-wrap break-words", children: JSON.stringify(reduxState, null, 2) }) })] }) })] }));
}
