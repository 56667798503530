import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "components/Input/HTMLEditor/htmlEditor.css";

export default function QuillEditor(props) {
  const { value, onChange, disabled = false } = props;

  return (
    <ReactQuill
      theme="snow"
      className="min-h-48 prose max-w-[100%]"
      value={value}
      onChange={onChange}
      readOnly={disabled}
    />
  );
}
