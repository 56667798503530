import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { T } from "@tolgee/react";
import { useEffect } from "react";
import tolgee from "services/translation";
import Snippet from "pages/Client/RepPages/components/Snippet";
import Certificate from "pages/Client/RepPages/components/Certificate";
import Info from "pages/Client/RepPages/UKRep/Components/Info";
export default function UKRep() {
    useEffect(function () {
        document.title = tolgee.t({ key: "uk_rep", ns: "gdpr" });
    }, []);
    return (_jsxs("div", { className: "flex flex-col mx-auto max-w-7xl", "data-testid": "ukrep-page", children: [_jsx("h1", { children: _jsx(T, { ns: "gdpr", keyName: "ukrep_title" }) }), _jsx(Info, {}), _jsx(Snippet, { type: "ukrep" }), _jsx(Certificate, { type: "ukrep" })] }));
}
