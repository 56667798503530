export var providerState = {
    case: {
        created_at: null,
        updated_at: null,
        title: null,
        type: null,
        state: null,
        uuid: null,
        histories: [],
        case_actors: [],
        business: null,
        email_alias: null,
        inquiries: [],
        status: null,
        deadline: null,
        detail: null,
        schedules: [],
        tags: [],
        files: [],
        outcome: null,
        ds_data: [],
        ds_email_verified: null,
        email: null,
        rights_concerned: [],
        case_stages: [],
        case_checks: [],
    },
    loading: false,
    inquiry: {
        open: false,
        questions: [],
    },
    ui: {
        stage: "PRE_CHECK",
        check: "DS_LOCATION",
    },
};
export default providerState;
