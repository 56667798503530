import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { T } from "@tolgee/react";
import NavItem from "components/Layout/Navbar/NavItem";
export default function NavMenu(props) {
    var _a;
    var navMenu = props.navMenu;
    return (_jsxs("div", { children: [!navMenu.url && navMenu.i18n && (_jsx("div", { className: "mt-4 mb-1 font-semibold uppercase text-prighterblue", children: _jsx(T, { keyName: navMenu.i18n.key, ns: navMenu.i18n.ns, params: navMenu.i18n.params }) })), (_a = navMenu === null || navMenu === void 0 ? void 0 : navMenu.sub_menu) === null || _a === void 0 ? void 0 : _a.map(function (menu, ind) {
                var _a;
                return (_jsx("div", { className: "grid gap-1", children: ((_a = navMenu === null || navMenu === void 0 ? void 0 : navMenu.sub_menu) === null || _a === void 0 ? void 0 : _a.length) > 0 && _jsx(NavMenu, { navMenu: menu }) }, "sub-".concat(ind)));
            }), (navMenu === null || navMenu === void 0 ? void 0 : navMenu.i18n) && (navMenu === null || navMenu === void 0 ? void 0 : navMenu.url) && (navMenu === null || navMenu === void 0 ? void 0 : navMenu.type) && (_jsx(NavItem, { i18n: navMenu.i18n, url: navMenu.url, icon: navMenu === null || navMenu === void 0 ? void 0 : navMenu.icon, type: navMenu.type, notificationCount: navMenu.count, todo: navMenu.todo }))] }));
}
