var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { Autocomplete, Button, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";
import { useCallback, useEffect, useState } from "react";
import tolgee from "services/translation";
import { BaseButton } from "components";
import ContactModal from "pages/Client/ContactDirectory/Components/ContactModal";
import { api } from "services";
import { notify } from "store/app";
import { useAppDispatch } from "store";
import { useCase } from "pages/Client/Case/CaseContext";
import { finalizeCheck, reopenCheck } from "store/thunks";
import { isCheckFinished } from "helpers/case";
export default function IncorrectSources() {
    var _this = this;
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var caseData = state.case;
    var isFinished = isCheckFinished(state);
    var requestClosed = caseData.state === "CLOSED";
    var appDispatch = useAppDispatch();
    var _b = useState(["create_contact"]), contactData = _b[0], setContactData = _b[1];
    var _c = useState([]), chosenContact = _c[0], setChosenContact = _c[1];
    var _d = useState(false), contactModal = _d[0], setContactModal = _d[1];
    var _e = useState(""), search = _e[0], setSearch = _e[1];
    var fetchContactData = useCallback(function (paginationProps) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getContactEmailData(paginationProps)];
                case 1:
                    res = _a.sent();
                    setContactData(__spreadArray(["create_contact"], res, true));
                    return [2 /*return*/];
            }
        });
    }); }, []);
    useEffect(function () {
        function syncFetchedData() {
            return __awaiter(this, void 0, void 0, function () {
                var res, incorrectSources;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getContactEmailData({
                                orderBy: "person_name",
                                page: 1,
                                perPage: 100,
                                order: false,
                                searchTerm: "",
                            })];
                        case 1:
                            res = _a.sent();
                            incorrectSources = caseData.outcome.incorrect_sources.map(function (source) { return source.contact_id; });
                            setChosenContact(res.filter(function (contact) { return incorrectSources.includes(contact.uuid); }));
                            setContactData(__spreadArray(["create_contact"], res, true));
                            return [2 /*return*/];
                    }
                });
            });
        }
        syncFetchedData();
    }, [state]);
    var generateLabel = function (option) {
        var _a, _b;
        if (option === "create_contact") {
            return tolgee.t({ key: "create_contact", ns: "dsr" });
        }
        var result = [];
        result.push((_a = option.person_name) === null || _a === void 0 ? void 0 : _a.trim());
        result.push((_b = option.organization_name) === null || _b === void 0 ? void 0 : _b.trim());
        var label = result.filter(function (value) { return value; }).join(" / ");
        var email = option.emails.length ? "(".concat(option.emails[0], ")") : "";
        return "".concat(label, " ").concat(email);
    };
    function getContactEmailData(paginationProps) {
        return __awaiter(this, void 0, void 0, function () {
            var orderBy, page, perPage, order, searchTerm, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        orderBy = paginationProps.orderBy, page = paginationProps.page, perPage = paginationProps.perPage, order = paginationProps.order, searchTerm = paginationProps.searchTerm;
                        return [4 /*yield*/, api.contactDirectory.getContacts(orderBy, page, perPage, order, searchTerm)];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res.result];
                }
            });
        });
    }
    var handleChangeController = function (_, __, reason, details) {
        var _a;
        if (((_a = details.option) === null || _a === void 0 ? void 0 : _a.uuid) === "create_person") {
            setContactModal(true);
            setSearch("");
            return;
        }
        if (reason === "selectOption") {
            setChosenContact(function (prevContact) { return __spreadArray(__spreadArray([], prevContact, true), [details.option], false); });
        }
        else if (reason === "removeOption") {
            setChosenContact(function (prevContact) {
                return prevContact.filter(function (contact) { return contact.uuid !== details.option.uuid; });
            });
        }
        setSearch("");
    };
    var handleCloseModal = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setSearch("");
                    return [4 /*yield*/, fetchContactData({
                            orderBy: "person_name",
                            page: 1,
                            perPage: 100,
                            order: false,
                            searchTerm: "",
                        })];
                case 1:
                    _a.sent();
                    setContactModal(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleCompleteIncorrectSources = function () { return __awaiter(_this, void 0, void 0, function () {
        var incorrectSources, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    incorrectSources = chosenContact.map(function (contact) {
                        return { contact_id: contact.uuid, url: "", comment: "" };
                    });
                    return [4 /*yield*/, api.ccpa.updateIncorrectSources(caseData.uuid, incorrectSources)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        appDispatch(notify({ message: tolgee.t({ key: "success", ns: "generic" }), type: "SUCCESS" }));
                        finalizeCheck()(dispatch, caseData.uuid, state.ui.check);
                    }
                    else {
                        appDispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: "pt-4", children: [_jsx(T, { keyName: "description.incorrect_sources", ns: "ccpa" }), _jsxs("div", { className: "space-x-2 mt-4 flex justify-end", children: [isFinished && !requestClosed && (_jsx(Button, { onClick: function () { return reopenCheck()(dispatch, caseData.uuid, state.ui.check); }, variant: "outlined", endIcon: _jsx(FontAwesomeIcon, { icon: "lock-open", className: "!text-sm" }), children: tolgee.t({ key: "reopen", ns: "dsr" }) })), _jsx(BaseButton, { disabled: isFinished, onClick: handleCompleteIncorrectSources, color: "success", children: tolgee.t({ key: "incorrect_sources.confirm", ns: "dsr" }) })] }), _jsx(Autocomplete, { disabled: isFinished && !requestClosed, multiple: true, value: chosenContact, disableClearable: true, options: contactData, style: { marginTop: "10px" }, getOptionLabel: function (option) { return generateLabel(option); }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { label: tolgee.t({ key: "create_controller", ns: "ccpa" }), required: true, variant: "outlined" }))); }, onChange: handleChangeController, inputValue: search, onInputChange: function (_, newSearch) {
                    setSearch(newSearch);
                } }), _jsx(ContactModal, { modalState: [contactModal, handleCloseModal], emailRequired: true })] }));
}
