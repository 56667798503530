import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useId, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, TextField } from "@mui/material";
import { ConfirmationModal, BaseModal } from "components/DataDisplay/Modal";
export default function EditableText(props) {
    var value = props.value, _a = props.onChange, onChange = _a === void 0 ? function () { } : _a, className = props.className, _b = props.parentSubmit, parentSubmit = _b === void 0 ? function () { } : _b, _c = props.modalState, modalState = _c === void 0 ? [] : _c, _d = props.confirmation, confirmation = _d === void 0 ? true : _d, children = props.children;
    var _e = useState(false), clicked = _e[0], setClicked = _e[1];
    var _f = useState(false), open = _f[0], setOpen = _f[1];
    var _g = useState(value), initialVal = _g[0], setInitialVal = _g[1];
    var closeModal = modalState[0], setCloseModal = modalState[1];
    var id = useId();
    var tr = useRef();
    useEffect(function () {
        if (clicked && !children && typeof value === "string") {
            tr.current.focus();
            tr.current.selectionStart = value.length;
        }
        if (clicked && children) {
            setOpen(true);
        }
    }, [clicked, children]);
    var handleClose = function () {
        setCloseModal(false);
        setClicked(false);
        setOpen(false);
    };
    useEffect(function () {
        if (closeModal === true) {
            handleClose();
        }
    }, [closeModal]);
    var handleChange = function (event) {
        if (typeof value === "string") {
            onChange(event.target.value);
        }
    };
    var handleClick = function () {
        setClicked(true);
    };
    var handleSubmit = function () {
        if (confirmation) {
            setOpen(true);
        }
        else {
            handleConfirm();
        }
    };
    var handleConfirm = function () {
        parentSubmit();
        setClicked(false);
        setInitialVal(value);
    };
    var handleNo = function () {
        if (typeof initialVal === "string") {
            onChange(initialVal);
        }
        setClicked(false);
    };
    return (_jsxs("div", { className: className, children: [(!clicked || children) && (_jsxs("div", { className: "flex flex-row", children: [_jsx("div", { className: "w-full min-h-[32px] break-all py-1 pl-2 pr-0.5", children: value }), _jsx(IconButton, { size: "small", className: "w-4 h-4 self-start", onClick: handleClick, children: _jsx(FontAwesomeIcon, { icon: "pen-to-square", color: "gray", size: "xs" }) })] })), clicked && !children && (_jsxs(_Fragment, { children: [_jsx(TextField, { className: "w-full", id: id, ref: tr, autoFocus: true, value: value, onChange: handleChange, onBlur: handleSubmit }), confirmation && (_jsx(ConfirmationModal, { modalState: [open, setOpen], onYes: handleConfirm, onNo: handleNo, titleText: "Are you sure you want to edit this value?" }))] })), clicked && children && (_jsx(BaseModal, { modalState: [open, setOpen], closeEvent: function () {
                    setCloseModal(true);
                }, children: children }))] }));
}
