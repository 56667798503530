var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Button, MenuItem, TextField, } from "@mui/material";
import { T } from "@tolgee/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { capitalizeFirstLetter } from "helpers/general";
import { api } from "services";
import tolgee from "services/translation";
import { notify } from "store/app";
export default function Snippet() {
    var _this = this;
    var businessId = useParams().businessId;
    var dispatch = useDispatch();
    var _a = useState(), locations = _a[0], setLocations = _a[1];
    var _b = useForm(), watch = _b.watch, setValue = _b.setValue, control = _b.control;
    var selectedLocation = watch("selectedLocation");
    useEffect(function () {
        fetchLocationsData();
        fetchCurrentLocationData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessId]);
    var fetchLocationsData = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, sorted_locations;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.generic.getEURepLocations()];
                case 1:
                    res = _a.sent();
                    if (!res) {
                        dispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                        return [2 /*return*/];
                    }
                    sorted_locations = Object.keys(res).sort(function (a, b) { return a.localeCompare(b); });
                    setLocations(sorted_locations);
                    return [2 /*return*/];
            }
        });
    }); };
    var fetchCurrentLocationData = function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.business.getEURepLocation(businessId)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        setValue("selectedLocation", res);
                    }
                    else {
                        dispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSetLocation = function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.business.updateEURepLocation(businessId, { country: selectedLocation })];
                case 1:
                    res = _a.sent();
                    if (res) {
                        dispatch(notify({
                            message: tolgee.t({ key: "location_updated", ns: "gdpr" }),
                            type: "SUCCESS",
                        }));
                    }
                    else {
                        dispatch(notify({ message: tolgee.t({ key: "smth_went_wrong", ns: "generic" }), type: "ERROR" }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { className: "box-outerlayout pb-6 pt-10 mt-6", "data-testid": "location-section", children: _jsxs("div", { className: "md:p-6 w-full", children: [_jsx("h2", { children: _jsx(T, { keyName: "eurep_location_title", ns: "gdpr" }) }), _jsxs("div", { className: "flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8", children: [_jsxs("div", { children: [_jsx(T, { keyName: "eurep_when_choosing", ns: "gdpr" }), _jsxs(Accordion, { className: "p-2 mt-6", children: [_jsx(AccordionSummary, { expandIcon: _jsx(FontAwesomeIcon, { size: "lg", icon: "angle-down" }), "aria-controls": "panel1a-content", id: "panel1a-header", children: tolgee.t({ key: "legal_implication", ns: "gdpr" }) }), _jsx(AccordionDetails, { children: tolgee.t({ key: "legal_implication_full", ns: "gdpr" }) })] })] }), _jsxs("div", { children: [_jsx(Controller, { name: "selectedLocation", control: control, defaultValue: false, render: function (_a) {
                                        var _b = _a.field, value = _b.value, ref = _b.ref, field = __rest(_b, ["value", "ref"]);
                                        return (_jsx(TextField, __assign({}, field, { inputRef: ref, select: true, value: value, defaultValue: value, onChange: function (event) {
                                                var _a;
                                                setValue("selectedLocation", (_a = event.target) === null || _a === void 0 ? void 0 : _a.value);
                                            }, label: tolgee.t({ key: "address", ns: "gdpr" }), className: "w-full md:w-100 mt-4", children: locations === null || locations === void 0 ? void 0 : locations.map(function (location, idx) { return (_jsx(MenuItem, { value: location, children: capitalizeFirstLetter(location) }, idx)); }) })));
                                    } }), _jsx("div", { className: "flex justify-end mt-6", children: _jsx(Button, { onClick: handleSetLocation, variant: "contained", children: _jsx(T, { keyName: "set_address", ns: "gdpr" }) }) })] })] })] }) }));
}
