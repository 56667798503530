var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from "react";
import { isNaN } from "lodash";
import { HexColorPicker } from "react-colorful";
import { Button, IconButton, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import BaseModal from "components/DataDisplay/Modal/BaseModal";
var format = function (number) {
    var hex = number.toString(16);
    return hex.length < 2 ? "0".concat(hex) : hex;
};
var hexToRgb = function (hexColor) {
    var hex = hexColor;
    if (hex[0] === "#") {
        hex = hex.substr(1);
    }
    if (hex.length < 6) {
        return {
            r: parseInt(hex[0] + hex[0], 16),
            g: parseInt(hex[1] + hex[1], 16),
            b: parseInt(hex[2] + hex[2], 16),
        };
    }
    return {
        r: parseInt(hex.substr(0, 2), 16),
        g: parseInt(hex.substr(2, 2), 16),
        b: parseInt(hex.substr(4, 2), 16),
    };
};
var rgbToHex = function (_a) {
    var r = _a.r, g = _a.g, b = _a.b;
    return "#".concat(format(r)).concat(format(g)).concat(format(b));
};
var onlyNumbers = function (num) { return num.replace(/([^0-9]+)/gi, "").substr(0, 3); };
function ColorPicker(_a) {
    var label = _a.label, color = _a.color, onChange = _a.onChange, handleResetDefault = _a.handleResetDefault;
    var _b = useState({ r: 0, g: 0, b: 0 }), rgb = _b[0], setRgb = _b[1];
    var _c = useState(false), pickerOpen = _c[0], setPickerOpen = _c[1];
    useEffect(function () {
        setRgb(hexToRgb(color));
    }, [color]);
    var handleRgbChange = function (prop, colorNum) {
        var _a;
        var shade = onlyNumbers(colorNum);
        var parsedColor = parseInt(shade, 10);
        if (isNaN(parsedColor)) {
            return;
        }
        var newRgb = __assign(__assign({}, rgb), (_a = {}, _a[prop] = parsedColor, _a));
        if (parsedColor > 0 && parsedColor <= 255) {
            onChange(rgbToHex(newRgb));
        }
        setRgb(newRgb);
    };
    var handleRgbBlur = function () {
        setRgb(hexToRgb(color));
    };
    return (_jsxs("div", { className: "flex space-x-2 items-center", children: [_jsx("div", { className: "font-bold", children: label }), _jsx(IconButton, { size: "large", "aria-label": "pick color", sx: { backgroundColor: color, border: "1px solid #cdcdcd" }, onClick: function (evt) {
                    evt.stopPropagation();
                    setPickerOpen(!pickerOpen);
                } }), _jsx(Button, { variant: "text", onClick: handleResetDefault, children: _jsx(T, { ns: "generic", keyName: "set_default" }) }), _jsxs(BaseModal, { className: "flex flex-col items-center space-y-4", modalState: [pickerOpen, setPickerOpen], closeEvent: function () { return setPickerOpen(false); }, children: [_jsx(HexColorPicker, { color: color, onChange: onChange }), _jsxs("div", { className: "space-x-2", children: [_jsx(TextField, { label: "R", className: "w-16", value: rgb.r, spellCheck: false, onBlur: handleRgbBlur, onChange: function (evt) { return handleRgbChange("r", evt.target.value); }, inputProps: { "aria-label": "red shade" } }), _jsx(TextField, { label: "G", className: "w-16", value: rgb.g, spellCheck: false, onBlur: handleRgbBlur, onChange: function (evt) { return handleRgbChange("g", evt.target.value); }, inputProps: { "aria-label": "green shade" } }), _jsx(TextField, { label: "B", className: "w-16", value: rgb.b, spellCheck: false, onBlur: handleRgbBlur, onChange: function (evt) { return handleRgbChange("b", evt.target.value); }, inputProps: { "aria-label": "blue shade" } })] })] })] }));
}
export default ColorPicker;
