var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useId, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { BaseButton, BaseIconButton } from "components/Button";
export default function FormFileField(props) {
    var label = props.label, className = props.className, required = props.required, _a = props.multiple, multiple = _a === void 0 ? false : _a, _b = props.fullWidth, fullWidth = _b === void 0 ? false : _b, _c = props.disabled, disabled = _c === void 0 ? false : _c, _d = props.value, value = _d === void 0 ? [] : _d, _e = props.onChange, onChange = _e === void 0 ? function () { } : _e;
    var id = useId();
    var hiddenFileInput = useRef(null);
    var _f = useState(value), files = _f[0], setFiles = _f[1];
    var handleClick = function () {
        hiddenFileInput.current.click();
    };
    var handleChange = function (event) {
        if (multiple) {
            var tempFiles = __spreadArray(__spreadArray([], files, true), Array.from(event.target.files), true);
            onChange(tempFiles);
            setFiles(tempFiles);
        }
        else {
            onChange([event.target.files[0]]);
            setFiles([event.target.files[0]]);
        }
    };
    var handleRemove = function (file) {
        var tempFiles = files.filter(function (oldFile) { return oldFile !== file; });
        onChange(tempFiles);
        setFiles(tempFiles);
    };
    return (_jsx("div", { className: "flex flex-col items-end space-y-4", children: _jsx("div", { className: clsx({ "w-full": fullWidth }, "flex flex-row items-center space-x-2"), children: _jsxs("div", { className: clsx({ "w-full": fullWidth }, className, "border-2 rounded-2xl border-dashed border-prighterblue flex flex-col items-center p-4 w-72"), children: [_jsxs(BaseButton, { variant: "outlined", onClick: handleClick, disabled: disabled, children: [label, " ", required && "*"] }), _jsx("input", { type: "file", ref: hiddenFileInput, className: "hidden", multiple: multiple, onChange: handleChange }), multiple ? (_jsxs("label", { htmlFor: id, className: clsx({
                            "!text-gray-400": disabled,
                        }, "flex flex-col items-start space-y-2 p-2 pl-1 pb-1 text-gray-600"), children: [files.map(function (file) { return (_jsxs("div", { className: "flex flex-row items-center", children: [_jsx("div", { className: "border-2 border-dashed rounded-full border-prighterblue py-2 px-4 mr-1", children: file.name }), _jsx(BaseIconButton, { disabled: disabled, onClick: function () { return handleRemove(file); }, className: "w-5 h-5", size: "medium", children: _jsx(FontAwesomeIcon, { size: "xs", icon: "xmark", color: "danger" }) })] }, file.name)); }), value.length === 0 && "No file".concat(multiple ? "s" : "", " selected")] })) : (_jsx("label", { htmlFor: id, className: "flex flex-col items-start space-y-2 p-2 pl-1 pb-1 text-gray-600", children: value.length === 0 ? "No file".concat(multiple ? "s" : "", " selected") : value[0].name }))] }) }) }));
}
