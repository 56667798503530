import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useId, useRef } from "react";
import clsx from "clsx";
import "components/Input/TextField/textFieldStyles.css";
export default function BaseTextField(props) {
    var _a = props.disabled, disabled = _a === void 0 ? false : _a, _b = props.fakeDisabled, fakeDisabled = _b === void 0 ? false : _b, _c = props.shrink, shrink = _c === void 0 ? false : _c, _d = props.required, required = _d === void 0 ? false : _d, _e = props.boldLabel, boldLabel = _e === void 0 ? false : _e, _f = props.multiline, multiline = _f === void 0 ? false : _f, _g = props.grow, grow = _g === void 0 ? multiline || false : _g, _h = props.label, label = _h === void 0 ? "" : _h, name = props.name, startAdornment = props.startAdornment, endAdornment = props.endAdornment, onChange = props.onChange, onClick = props.onClick, value = props.value, _j = props.className, className = _j === void 0 ? "" : _j, _k = props.containerClassName, containerClassName = _k === void 0 ? "" : _k, _l = props.textareaClassName, textareaClassName = _l === void 0 ? className : _l, _m = props.testid, testid = _m === void 0 ? "" : _m;
    var id = useId();
    var tr = useRef();
    useEffect(function () {
        if (grow) {
            tr.current.style.height = "auto";
            tr.current.style.height = "".concat(tr.current.scrollHeight + 1, "px");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    var handleKeyDown = function (e) {
        if (!grow && e.key === "Enter") {
            e.preventDefault();
        }
    };
    return (_jsxs("div", { "data-testid": testid, className: clsx(containerClassName, "input-container relative flex flex-col"), children: [_jsx("textarea", { id: id, ref: tr, name: name, value: value || "", required: required, readOnly: !onChange, onClick: onClick, onKeyDown: handleKeyDown, onChange: onChange, rows: multiline ? 4 : 1, disabled: disabled, className: clsx(textareaClassName, "rounded border border-solid border-gray-400 text-base px-3 py-4 shadow-none outline-none resize-none", { "whitespace-nowrap overflow-x-hidden": !grow, "overflow-hidden": grow }, { "hover:border-gray-800": !disabled && !fakeDisabled }, { "text-gray-500 caret-transparent": disabled || fakeDisabled }, { "pr-10": endAdornment }, { "cursor-pointer": onClick }) }), _jsxs("label", { className: clsx({ "text-gray-400": disabled || fakeDisabled }, {
                    "filled text-prighterblue bg-white": value || shrink,
                }, { "font-bold": boldLabel }, "px-2 py-0 m-0 leading-3 text-gray-700 focus:text-prighterblue absolute pointer-events-none"), htmlFor: id, children: [label, " ", required && "*"] }), endAdornment && _jsx("div", { className: "absolute right-3 top-3", children: endAdornment }), startAdornment && _jsx("div", { className: "absolute left-3 top-3", children: startAdornment })] }));
}
